var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"document"},[_c('div',{staticClass:"document-wrap",class:'docAccordion_' + _vm.document.doc_name || _vm.docNames[_vm.document.doc_id]},[_c('v-expansion-panels',{model:{value:(_vm.folderPanel),callback:function ($$v) {_vm.folderPanel=$$v},expression:"folderPanel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticClass:"document-header"},[(_vm.isCorrect)?_c('v-icon',{staticClass:"mr-3 mb-1",staticStyle:{"flex":"0"},attrs:{"small":"","color":"green"}},[_vm._v(" $IconGalka ")]):(_vm.isRejected && (_vm.confirm || _vm.rejecting))?_c('v-icon',{staticClass:"mr-3",staticStyle:{"flex":"0"},attrs:{"x-small":"","color":"red"}},[_vm._v("$IconClose")]):(_vm.isHold)?_c('v-icon',{staticClass:"mr-2 iconWait",staticStyle:{"flex":"0"},attrs:{"small":"","color":"red"}},[_vm._v("mdi-sync")]):_vm._e(),_c('div',{staticClass:"document-title text-h7",class:_vm.fromTask ? 'document-title--task' : ''},[_vm._v(" "+_vm._s(_vm.document.doc_name ? _vm.document.doc_name : _vm.docNames[_vm.document.doc_id])+" ")])],1),_c('v-expansion-panel-content',[(_vm.showScan && _vm.document.path_doc)?_c('div',[_c('span',[_vm._v("Скан:")]),_c('a',{attrs:{"download":"","href":_vm.$root.env.VUE_APP_STORE + _vm.document.path_doc}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $IconDocument ")])],1)]):_vm._e(),_c('v-row',{staticClass:"justify-space-between"},[(_vm.showFields)?_c('v-col',{staticClass:"document-fields",attrs:{"cols":"12","sm":_vm.showDropzone ? 6 : 12}},[_c('v-row',_vm._l((_vm.fieldsData.length
                    ? _vm.fieldsData
                    : 10),function(field,fieldKey){return _c('v-col',{key:fieldKey,staticClass:"field-col",class:field.type,attrs:{"cols":12,"sm":6}},[(_vm.loading)?_c('div',{staticClass:"field-loading gradient"}):(field.type === 'string')?_c('v-text-field',{class:'docInput_' + field.name,attrs:{"label":field.label,"error-messages":_vm.formErrors[field.name],"clearable":"","readonly":field.readonly || _vm.confirm,"disabled":field.readonly,"name":'docInput_' + field.name},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'date')?_c('Datepicker',{class:'docInput_' + field.name,attrs:{"label":field.label,"field":field,"error-messages":_vm.formErrors[field?.name],"readonly":_vm.confirm},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'checkbox')?_c('v-checkbox',{class:'docInput_' + field.name,attrs:{"label":field.label,"readonly":_vm.confirm},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'select')?_c('Autocomplete',{ref:"autocompleteRef",refInFor:true,class:'docInput_' + field.name,attrs:{"field":{
                      ...field,
                    },"error-messages":_vm.formErrors[field?.name],"formData":_vm.formData,"readonly":_vm.confirm},on:{"change":_vm.changeAutocomplete},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'autocomplete')?_c('Autocomplete',{ref:"autocompleteRef",refInFor:true,class:'docInput_' + field.name,attrs:{"field":{
                      ...field,
                    },"error-messages":_vm.formErrors[field?.name],"formData":_vm.formData,"readonly":_vm.confirm,"getDepFilters":_vm.getDepFilters},on:{"change":_vm.changeAutocomplete},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):_vm._e()],1)}),1)],1):_vm._e(),(_vm.showDropzone)?_c('v-col',{attrs:{"cols":"12","sm":_vm.showDropzone && !_vm.showFields ? 12 : 5}},[_c('div',{staticClass:"document-scan"},[(!_vm.withoutSave && _vm.showActions)?_c('div',{staticClass:"document-scan-preview-panel"},[(!_vm.isEdit && _vm.pathDock.length)?_c('v-icon',{staticClass:"document-scan-preview-panel__icon",on:{"click":_vm.toEdit}},[_vm._v(" $IconEdit ")]):_vm._e(),(_vm.isEdit)?_c('v-icon',{staticClass:"document-scan-preview-panel__icon",on:{"click":_vm.toPreview}},[_vm._v(" $IconArrowCansel ")]):_vm._e()],1):_vm._e(),(_vm.document.path_doc && !_vm.isEdit && !_vm.showScan)?_c('div',{staticClass:"document-scan-preview"},[_c('a',{attrs:{"target":"_blank","href":_vm.$root.env.VUE_APP_STORE + _vm.pathDock[0]}},[_c('img',{attrs:{"src":_vm.$root.env.VUE_APP_STORE + _vm.pathDock[0],"alt":_vm.$root.env.VUE_APP_STORE + _vm.pathDock[0]}})])]):_vm._e(),_c('DropZone',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.document.path_doc && !_vm.isEdit && !_vm.showScan)),expression:"!(document.path_doc && !isEdit && !showScan)"}],ref:"dropZoneRef",attrs:{"options":{
                    maxFiles: 1,
                    removeble: true,
                    withoutSave: false,
                    folder: 'personal_doc',
                    name: '`personal_doc`',
                  },"error-messages":_vm.formErrors[_vm.field?.name]},on:{"addFiles":function($event){return _vm.addFiles($event, _vm.field)},"removeFile":_vm.removeFile}})],1)]):_vm._e(),(_vm.document.commentError)?_c('FormError',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.document.commentError)+" ")]):_vm._e()],1),(_vm.acceptDocPanel)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"disabled":_vm.vForm.$invalid,"color":"primary","small":""},on:{"click":_vm.sendBankCard}},[_vm._v(" Завершить ")])],1):_vm._e(),(_vm.correct)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"disabled":_vm.vForm.$invalid,"color":"warning","name":`${_vm.docNames[_vm.document.doc_id]}_correct_btn`,"small":""},on:{"click":_vm.confirmCorrect}},[_vm._v(" Исправлено ")])],1):_vm._e(),(_vm.confirm)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"name":`${_vm.docNames[_vm.document.doc_id]}_reject_btn`,"color":"error","small":""},on:{"click":_vm.rejectDoc}},[_vm._v(" Отклонить ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"name":`${_vm.docNames[_vm.document.doc_id]}_accept_btn`,"color":"primary","small":""},on:{"click":_vm.confirmDoc}},[_vm._v(" Подтвердить ")])],1):_vm._e(),(_vm.rejecting)?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{attrs:{"name":`${_vm.docNames[_vm.document.doc_id]}_reject_btn`,"color":"error","small":""},on:{"click":_vm.rejectDoc}},[_vm._v(" Отклонить ")])],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"document-file"})],1),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }