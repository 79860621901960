import Vue, { onMounted, computed, toRef, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'
import Popup from '@/components/Popup/index.vue'
import { useRouter, useRoute } from 'vue-router/composables'
import useForm from '@/compositions/useForm.js'
import useRequest from '@/compositions/useRequest'
import Autocomplete from '@/components/Autocomplete/default'

export default {
  props: {
    request: {
      type: Function,
    },
    entity: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: { Autocomplete, Popup },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(false)
    const isShow = ref(false)

    const disabled = computed(() => {
      return !(
        formData.value.name &&
        (formData.value.type_id || !props.options?.contractType) &&
        (formData.value.territory_id || !props.options?.territory)
      )
    })

    const formTemplate = {
      id: undefined,
      name: '',
      type_id: undefined,
      territory_id: undefined,
    }
    const formData = ref()

    const contractConfig = {
      items: [],
      label: 'Тип договора',
      // dense: true,
      // hideDetails: true,
      selectOption: {
        text: 'name',
        value: 'id',
      },
    }

    const territoryConfig = {
      items: [],
      label: 'Территория',
      // dense: true,
      // hideDetails: true,
      selectOption: {
        text: 'name',
        value: 'id',
      },
    }

    const confirmChanges = async () => {
      loading.value = true
      try {
        const response = await props.request({ formData: formData.value })
        if (response.code === 1) {
          emit('refresh')
          isShow.value = false
          store.commit('notifies/showMessage', {
            color: 'success',
            content: 'Сохранено',
            timeout: 1000,
          })
        } else if (response.code === 2) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Ошибка сервера',
            timeout: 1000,
          })
        } else if (response.code === 3) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Невалидные данные',
            timeout: 1000,
          })
        } else if (response.code === 4) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 1000,
          })
        } else if (response.code === 5) {
          store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Уже существует',
            timeout: 1000,
          })
        }
      } finally {
        loading.value = false
      }
    }

    const initConfig = () => {
      formData.value = _.cloneDeep(formTemplate)
      if (props.entity)
        Object.entries(props.entity).forEach(([key, value]) => {
          Vue.set(formData.value, key, value)
        })
    }

    watch(
      () => isShow.value,
      () => {
        initConfig()
      },
      { immediate: true }
    )

    return {
      isShow,
      loading,

      formData,
      contractConfig,
      territoryConfig,
      confirmChanges,
      disabled,
    }
  },
}
