var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-scene"},[_c('div',{staticClass:"v-card",class:[_vm.flipped && 'v-card__flipped', !_vm.data.id && 'v-card__default'],style:({
      background: _vm.cardStyle?.background,
      borderColor: _vm.cardStyle?.border,
    })},[_c('div',{staticClass:"v-card-side v-card-side__front d-flex justify-space-between flex-column"},[_c('v-row',{staticClass:"justify-space-between"},[_c('div',{class:['v-card-side_image', !_vm.data.id && 'gradient']},[(_vm.cardStyle)?_c('img',{attrs:{"src":require(`@/assets/image/bank/${_vm.cardStyle?.image}`)}}):_c('div',[_vm._v(_vm._s(_vm.data.bank_name))])]),(_vm.data.status_id !== 2)?_c('div',{class:['v-card-side-actions', !_vm.data.id && 'gradient']},[(_vm.data.note)?_c('v-menu',{attrs:{"open-on-hover":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.cardStyle?.actions}},[_vm._v("mdi-alert-circle-outline")])],1)]}}],null,false,3154692642)},[_c('div',{staticClass:"v-card-side-actions-menu"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Примечание")]),_c('div',{staticClass:"v-card-side-actions-menu_comment mt-3"},[_vm._v(" "+_vm._s(_vm.data.note)+" ")])])]):_vm._e(),_c('v-btn',{staticClass:"ml-2",attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.flipped = !_vm.flipped}}},[_c('v-icon',{attrs:{"small":"","color":_vm.cardStyle?.actions}},[_vm._v("$IconUpdate")])],1)],1):_vm._e()]),_c('div',[_c('span',{class:['v-card-side_number', !_vm.data.id && 'gradient'],style:({
            color: _vm.cardStyle?.text,
          })},[_vm._v(_vm._s(_vm.data.num?.replace(/(\d{4}(?!\s))/g, '$1 ') ?? '0000 0000 0000 0000'))]),_c('v-row',{staticClass:"justify-space-between align-end",staticStyle:{"margin-top":"10px"}},[_c('span',{class:['v-card-side_name', !_vm.data.id && 'gradient'],style:({
              color: _vm.cardStyle?.text,
            })},[_vm._v(_vm._s(_vm.data.fio ?? 'Иванов Иван Иванович'))]),_c('span',{class:['v-card-side_validity', 'mr-2', !_vm.data.id && 'gradient'],style:({
              color: _vm.cardStyle?.text,
            })},[_vm._v(_vm._s(_vm.data.date_valid ?? '00/00'))])])],1)],1),_c('div',{staticClass:"v-card-side v-card-side__back"},[_c('div',{staticClass:"v-card-side_upper"},[_c('v-btn',{attrs:{"icon":"","x-small":""},on:{"click":function($event){_vm.flipped = !_vm.flipped}}},[_c('v-icon',{attrs:{"small":"","color":_vm.cardStyle?.actions}},[_vm._v("$IconUpdate")])],1)],1),_c('div',{staticClass:"v-card-side_magnetic"}),_c('div',{staticClass:"v-card-side-backInfo"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"v-card-side-backInfo_signature"}),_c('span',{staticClass:"v-card-side-cvv",style:({
              color: _vm.cardStyle?.cvv,
            })},[_vm._v(_vm._s(_vm.data.csv))])]),_c('div',{staticClass:"v-card-side-backInfo_image"},[(_vm.cardStyle?.image)?_c('img',{attrs:{"src":require(`@/assets/image/bank/${_vm.cardStyle?.image}`)}}):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }