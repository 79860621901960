var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.openMenu)?_c('div',{staticClass:"v-sidebar"},[_c('v-navigation-drawer',{class:['v-sidebar-container'],attrs:{"absolute":_vm.isMobile,"temporary":_vm.isMobile,"permanent":_vm.openMenu,"mini-variant":_vm.miniMenu,"touchless":true,"mini-variant-width":"76px"},model:{value:(_vm.isMobile),callback:function ($$v) {_vm.isMobile=$$v},expression:"isMobile"}},[_c('div',{staticClass:"v-sidebar-container-user"},[_c('div',{staticClass:"v-sidebar-container-user_image"},[_c('v-avatar',[_c('v-icon',{attrs:{"x-large":""}},[_vm._v(" mdi-account-circle ")])],1)],1),(!_vm.miniMenu)?_c('div',{staticClass:"v-sidebar-container-user-info"},[_c('div',{staticClass:"v-sidebar-container-user-info_name"},[_vm._v(" "+_vm._s(_vm.userInfo.name)+" ")]),_c('div',{staticClass:"v-sidebar-container-user-info_email"},[_vm._v(" "+_vm._s(_vm.userInfo.email)+" ")])]):_vm._e()]),_c('div',{staticClass:"flex-grow-1 overflow-auto"},[(_vm.$props?.navData)?_c('v-expansion-panels',{attrs:{"multiple":"","color":"navbar"},model:{value:(_vm.navbarCurrentRoute),callback:function ($$v) {_vm.navbarCurrentRoute=$$v},expression:"navbarCurrentRoute"}},_vm._l((_vm.$props?.navData),function(item){return _c('v-expansion-panel',{key:item.id,attrs:{"color":"navbar"}},[(!item?.child)?[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('router-link',_vm._g({staticClass:"v-sidebar-container-link v-sidebar-container-link__default-height",class:!_vm.isMobileDevice && 'v-sidebar-container-link__hover',attrs:{"to":item?.link,"name":item.link,"id":`nav-chapter_${item.name}`}},_vm.miniMenu && !_vm.isMobileDevice && on),[_c('div',{staticClass:"v-sidebar-container-link_icon"},[_c('v-icon',{attrs:{"color":_vm.$route?.matched?.[0]?.path === item.link ||
                        item?.child?.some(
                          (e) => _vm.$route?.matched?.[0]?.path === e.link
                        )
                          ? 'primary'
                          : ''}},[_vm._v(_vm._s(item?.icon))])],1),(!_vm.miniMenu)?_c('div',{class:[
                      'v-sidebar-container-link_name',
                      _vm.$route?.matched?.[0]?.path === item.link &&
                        'v-sidebar-container-link_name__active',
                    ]},[_vm._v(" "+_vm._s(item?.name)+" ")]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]:(!_vm.miniMenu)?[_c('v-expansion-panel-header',{staticClass:"v-sidebar-container-link v-sidebar-container-link__default-height",class:[
                !_vm.isMobileDevice && 'v-sidebar-container-link__hover',
                `nav-button_${item.name}`,
              ]},[_c('div',{staticClass:"v-sidebar-container-link_icon"},[_c('v-icon',{attrs:{"color":_vm.$route?.path === item?.link ||
                    item?.child.some(
                      (e) => _vm.$route?.matched?.[0]?.path === e.link
                    )
                      ? 'primary'
                      : ''}},[_vm._v(_vm._s(item?.icon))])],1),(!_vm.miniMenu)?_c('div',{class:[
                  'v-sidebar-container-link_name',
                  (_vm.$route?.matched?.[0]?.path === item.link ||
                    item?.child.some(
                      (e) => _vm.$route?.matched?.[0]?.path === e.link
                    )) &&
                    'v-sidebar-container-link_name__active',
                ]},[_vm._v(" "+_vm._s(item?.name)+" ")]):_vm._e()]),(!_vm.miniMenu)?_vm._l((item?.child),function(link,index){return _c('v-expansion-panel-content',{key:index,class:[
                  'v-sidebar-container-link',
                  _vm.instantNav && 'v-sidebar-container-link__instant',
                  !_vm.isMobileDevice && 'v-sidebar-container-link__hover',
                  `nav-button_${link.name}`,
                ],attrs:{"color":"navbar"}},[_c('router-link',{staticClass:"text-decoration-none pl-6 pr-6 pb-4 d-block",attrs:{"to":link.link,"name":`${item.link}`}},[_c('div',{class:[
                      'v-sidebar-container-link_name',
                      'v-sidebar-container-link_name__shifted',
                      _vm.$route?.matched?.[0]?.path === link.link &&
                        'v-sidebar-container-link_name__active',
                    ]},[_vm._v(" "+_vm._s(link.name)+" ")])])],1)}):_vm._e()]:[_c('v-menu',{attrs:{"offset-x":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: onMenu }){return [_c('v-tooltip',{attrs:{"right":"","open-on-hover":!_vm.isMobileDevice},scopedSlots:_vm._u([{key:"activator",fn:function({ on: hint }){return [_c('div',_vm._g({staticClass:"v-sidebar-container-link v-sidebar-container-link__default-height",class:!_vm.isMobileDevice && 'v-sidebar-container-link__hover'},{ ...hint, ...onMenu }),[_c('div',{staticClass:"v-sidebar-container-link_icon"},[_c('v-icon',{attrs:{"color":_vm.$route?.matched?.[0]?.path === item.link ||
                            item?.child.some(
                              (e) => _vm.$route?.matched?.[0]?.path === e.link
                            )
                              ? 'primary'
                              : ''}},[_vm._v(_vm._s(item?.icon))])],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.name))])])]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"v-sidebar-container-link_title"},[_vm._v(" "+_vm._s(item.name)+" ")]),_vm._l((item?.child),function(link,index){return _c('v-list-item',{key:index,staticClass:"pl-0 pr-0"},[_c('router-link',{staticClass:"v-sidebar-container-link",class:!_vm.isMobileDevice && 'v-sidebar-container-link__hover',staticStyle:{"height":"100%","width":"100%"},attrs:{"to":link.link,"link":link.name}},[_c('div',{class:[
                        'v-sidebar-container-link_name',
                        _vm.$route?.matched?.[0]?.path === link.link &&
                          'v-sidebar-container-link_name__active',
                      ]},[_vm._v(" "+_vm._s(link.name)+" ")])])],1)})],2)],1)]],2)}),1):_vm._e()],1),_c('span',{staticClass:"d-flex align-end"},[_c('v-btn',{class:[
          'v-sidebar-container-btn',
          !_vm.isMobileDevice && 'v-sidebar-container-btn__hover',
        ],attrs:{"text":"","color":"navbar"},on:{"click":function($event){_vm.isMobile ? _vm.changeMenuStatus() : _vm.changeMenuSize()}}},[(!_vm.miniMenu)?_c('v-icon',{staticClass:"v-sidebar-container-btn_icon"},[_vm._v(" $IconArrowLeft ")]):_vm._e(),(_vm.miniMenu)?_c('v-icon',{staticClass:"v-sidebar-container-btn_icon",attrs:{"padding":"0","width":"12"}},[_vm._v(" $IconOpenMenu ")]):_vm._e(),(!_vm.miniMenu)?_c('div',{staticClass:"v-sidebar-container-btn_text"},[_vm._v(" Свернуть ")]):_vm._e()],1),(!_vm.miniMenu && _vm.version)?_c('span',{staticClass:"v-sidebar-container-version"},[_vm._v(_vm._s(_vm.version))]):_vm._e()],1)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }