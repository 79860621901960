var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding":"10px"}},[_c('PersTitle',{attrs:{"data":{
        surname: _vm.data.entity.surname,
        name_n: _vm.data.entity.name_n,
        patronymic: _vm.data.entity.patronymic,
        dataRojd: _vm.data.entity.data_rojd.split('-').reverse().join('.'),
      }}}),_c('TextInfo',{staticClass:"mb-2",attrs:{"infoObj":_vm.textInfo}}),_c('v-row',[_vm._v(" Проверьте документы: ")]),_c('FormError',[_vm._v(_vm._s(JSON.parse(_vm.data.task.dop_data).comment))]),_c('div',[[_vm._l((_vm.data.data.docs),function(item,index){return _c('v-row',{key:index,staticClass:"py-3",class:{
            confirmed: !_vm.isShowBtnArray[index],
          },attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('div',{staticClass:"accept-docs"},[_c('div',{staticStyle:{"width":"70px","height":"45px"}},[_c('img',{staticStyle:{"object-fit":"contain","width":"70px","height":"45px"},attrs:{"src":_vm.imagePreview[index]},on:{"click":() => {
                      _vm.setImageForPopup(index)
                      _vm.isImgPopupOpen = true
                    }}})]),_c('span',[_vm._v(_vm._s(_vm.data.data.docs_spr[item.doc_id]))])])]),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"fab":"","x-small":"","icon":""},on:{"click":function($event){return _vm.addToDenied(index)}}},[_c('v-icon',{attrs:{"color":_vm.isShowBtnArray[index] ? 'green' : '',"x-small":""}},[_vm._v("$IconGalka")])],1),_c('v-btn',{attrs:{"icon":"","fab":"","x-small":"","tag":"label"}},[_c('input',{ref:"file",refInFor:true,staticClass:"d-none",attrs:{"type":"file","id":"file","accept":"image/*"},on:{"change":function($event){return _vm.uploadChangedFile($event, index)}}}),_c('v-icon',{attrs:{"color":"orange","x-small":""}},[_vm._v("$IconEdit")])],1)],1)],1)}),_c('v-divider')]],2),_c('FormComment',{model:{value:(_vm.watchForComment),callback:function ($$v) {_vm.watchForComment=$$v},expression:"watchForComment"}})],1),(_vm.isImgPopupOpen)?_c('FormPopupPhoto',{attrs:{"src":_vm.imageShowPopup},on:{"close":function($event){_vm.isImgPopupOpen = false}}}):_vm._e(),_c('v-divider'),_c('v-row',{staticClass:"py-2 px-5",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{attrs:{"small":"","color":"info","disabled":!_vm.isLoadImage && !_vm.comment},on:{"click":_vm.sendDoneTask}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }