import Vue, { onMounted, computed, toRef, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import moment from 'moment'
import Datepicker from '@/components/Date/Default/index.vue'
import Popup from '@/components/Popup/index.vue'
import DropZone from '@/components/Dropzone/default/index.vue'
import { useRouter, useRoute } from 'vue-router/composables'
import { required, dateRange } from '@/utils/validation.js'
import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  checkboxField,
  dropZoneField,
  carouselField,
  docListField,
  colorPicker,
  textBlock,
} from '@/utils/fields.js'
import useForm from '@/compositions/useForm.js'
import useRequest from '@/compositions/useRequest'

export default {
  name: 'Pact-Dialog',
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    territory: {
      type: Object,
      default: () => {},
    },
    type: {
      type: Number,
      default: 0,
    },
    additional: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  components: { Datepicker, DropZone, Popup },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const isShow = ref(false)
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(false)
    const version = computed(() => {
      if (props.additional) {
        return `${props.document.version}.${props.document.items.length + 1}`
      } else {
        return `${props.document.items.length + 1}`
      }
    })

    const config = ref({
      prolonagation: true,
      date: true,
      ...props.options,
    })

    const { makeRequest: createForm } = useRequest({
      context,
      successMessage: 'Сохранено',
      request: async (params) => {
        let requestData
        if (props.additional && props.type === 2) {
          requestData = {
            name: params.formData.name,
            file: params.formData.file,
            date_from: '0000-00-00',
            date_to: '0000-00-00',
          }
        } else {
          requestData = params.formData
          delete requestData.with_prolongation
          delete requestData.prolongation_price
        }
        const response = await store.dispatch(params.module, {
          url: params.url,
          body: {
            data: {
              ...requestData,
              agreement_id: !props.additional ? props.document.id : undefined,
              version: Number(version.value),
              version_agreement_id: props.additional
                ? props.document.id
                : undefined,
              object_type_id: props.additional ? props.type : undefined,
            },
          },
        })
        if (response.code === 1) {
          emit('refresh')
          isShow.value = false
          if (formData.prolongation_tarif) {
            emit('openParser', {
              territory: props.territory,
              contract: props.document,
              version: Number(version.value) + 1,
              type: props.type,
            })
          }
        }
      },
    })

    const getUnix = (val) => {
      return moment(val, 'YYYY-MM-DD').unix()
    }

    const { formData, fields, clickHandler, formErrors, reset } = useForm({
      form: {
        detail: true,
        lists: [],
        fields: [
          stringField({
            label: 'name',
            name: 'name',
            placeholder: '',
            value: '',
            class: [''],
            validations: { required },
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          dateField({
            label: 'date_from',
            name: 'date_from',
            type: 'date',
            value: '',
            menu: false,
            placeholder: '',
            class: [''],
            isShow: !!config.value.date,
            validations: {
              required,
              dateRange: dateRange('date_from', 'date_to'),
            },
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          dateField({
            label: 'date_to',
            name: 'date_to',
            type: 'date',
            value: '',
            menu: false,
            placeholder: '',
            class: [''],
            isShow: !!config.value.date,
            validations: {
              required,
              dateRange: dateRange('date_from', 'date_to'),
            },
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          checkboxField({
            label: 'with_prolongation',
            name: 'with_prolongation',
            value: false,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          checkboxField({
            label: 'prolongation_price',
            name: 'prolongation_price',
            value: false,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          checkboxField({
            label: 'prolongation_tarif',
            name: 'prolongation_tarif',
            value: false,
            notSend: true,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
          }),
          dropZoneField({
            label: '',
            name: 'file',
            notPut: true,
            placeholder: '',
            class: [''],
            position: {
              cols: 12,
              sm: 12,
            },
            bootstrapClass: [''],
            validations: { required },
            options: {
              removeble: true,
              withoutSave: false,
              maxSize: 500,
              valueId: 'agreement',
              folder: 'agreement',
              name: '`agreement`',
              paramsForEmit: this,
              countFiles: 1,
            },
            value: [],
          }),
        ],
      },
      context,
      loading,
      createForm,
    })

    const initConfig = () => {
      formErrors.value = {}
      reset()
    }

    watch(
      () => isShow.value,
      () => {
        initConfig()
      }
    )

    watch(
      () => formData,
      () => {
        if (formData.with_prolongation) {
          formData.prolongation_tarif = false
        } else {
          formData.prolongation_price = false
          formData.with_prolongation = false
        }
      },
      { deep: true }
    )

    return {
      formData,
      clickHandler,
      fields,
      getUnix,
      isShow,
      version,
      formErrors,
      config,
      loading,
    }
  },
}
