import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
      default: 'mdi-text-box',
    },
    text: {
      type: String,
      default: 'Сущность не найдена',
    },
    padding: {
      type: [Number, String],
      default: 7,
    },
  },
  components: {},
  setup(props, ctx) {
    return {}
  },
})
