var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding-top":"20px"}},[_c('v-card-title',{staticClass:"py-1 justify-center font-weight-bold text-h6"},[_vm._v(" Назначение "),_c('span',{staticClass:"col-btn form-link",on:{"click":function($event){return _vm.pushToForm(_vm.entity.id)}}},[_vm._v(" №"+_vm._s(_vm.entity.id)+" ")]),_vm._v("на дату "+_vm._s(_vm.dateTarget)+" ")]),(
        _vm.data.entity.doljnost_id === 5 ||
        _vm.data.entity.doljnost_id === 6 ||
        _vm.data.entity.doljnost_id === 7 ||
        _vm.data.entity.doljnost_id === 8 ||
        _vm.data.entity.doljnost_id === 32 ||
        _vm.data.entity.doljnost_id === 33
      )?_c('div',[_c('TextInfo',{staticClass:"mb-5",attrs:{"infoObj":_vm.infoObj}}),_c('b',{staticClass:"fw-bold"},[_vm._v("Приложите выработку:")]),_c('Dropzone',{attrs:{"options":{
          withoutSave: false,
          folder: 'tmp',
          removeble: false,
        }},on:{"addFiles":_vm.addFiles}})],1):(
        _vm.data.entity.direction_id === 6 || _vm.data.entity.direction_id === 7
      )?_c('div',[(_vm.rejectedTarif)?_c('FormError',{staticClass:"mb-4"},[_vm._v(" Отсутствует тариф на услугу: "+_vm._s(_vm.rejectedTarif)+" ")]):_vm._e(),_c('div',[_c('v-row',[_c('div',[_c('span',[_vm._v("Укажите выработку за смену:")])])]),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","sm":"6"}},[_c('Autocomplete',{attrs:{"readonly":_vm.autocompleteConfig.readonly,"field":_vm.autocompleteConfig},on:{"change":(idService) => _vm.changeServiceDetail(idService)},model:{value:(_vm.selectName),callback:function ($$v) {_vm.selectName=$$v},expression:"selectName"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"label":"QTY"},model:{value:(_vm.qty),callback:function ($$v) {_vm.qty=$$v},expression:"qty"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"disabled":"","label":"Тариф"},model:{value:(_vm.tariff),callback:function ($$v) {_vm.tariff=$$v},expression:"tariff"}})],1),_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"label":"Сумма","disabled":""},model:{value:(_vm.sum),callback:function ($$v) {_vm.sum=$$v},expression:"sum"}})],1)],1)],1)],1):_vm._e()],1),_c('v-divider'),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"blue-grey","small":""},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),(
        _vm.data.entity.doljnost_id == 5 ||
        _vm.data.entity.doljnost_id === 6 ||
        _vm.data.entity.doljnost_id === 7 ||
        _vm.data.entity.doljnost_id === 8 ||
        _vm.data.entity.doljnost_id === 32 ||
        _vm.data.entity.doljnost_id === 33
      )?_c('v-btn',{staticClass:"mr-2",attrs:{"disabled":!_vm.isSetTask && _vm.tariff,"small":"","loading":_vm.loading,"color":"info"},on:{"click":_vm.completeTask}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconMain ")]),_vm._v(" Завершить ")],1):_vm._e(),(_vm.data.entity.direction_id == 6 || _vm.data.entity.direction_id == 7)?_c('v-btn',{attrs:{"disabled":!_vm.changeQTY || (_vm.data.entity.direction_id == 7 && !_vm.tariff),"color":"info","loading":_vm.loading,"small":""},on:{"click":_vm.completeTask}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" $IconMain ")]),_vm._v(" Завершить ")],1):_vm._e()],1),(
      _vm.proxyConfig.detail &&
      _vm.proxyConfig.detail.type === 'popup' &&
      _vm.popupForm.isShow
    )?_c(_vm.Popup,{tag:"component",attrs:{"options":{
      width: _vm.proxyConfig.detail.width,
      portal: 'table-detail',
    }}},[_c('router-view',{class:[
        ..._vm.proxyConfig.detail.bootstrapClass,
        ..._vm.proxyConfig.detail.classes,
      ],attrs:{"detail":_vm.proxyConfig.detail},on:{"closePopup":_vm.closePopupForm}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }