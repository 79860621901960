var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"trigger-container"},[_c('div',{ref:"container",class:['v-container', _vm.$route?.query?.mail && 'v-container__active'],on:{"scroll":_vm.triggerCheck}},[(_vm.$route?.query?.compose !== 'new')?_c('div',{ref:"containerBox",class:[
        'v-container-box',
        _vm.$route?.query?.mail && 'v-container-box__active',
      ],on:{"scroll":function($event){_vm.$route?.query?.mail && _vm.triggerCheck()}}},[(
          _vm.$route?.query?.filter !== 'folder' &&
          _vm.$route?.query?.filter !== 'box' &&
          _vm.$route?.query?.filter !== 'trans'
        )?[(_vm.trigger.left)?_c('div',{staticClass:"trigger trigger__left"},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.scrollContainer(-350)}}},[_c('v-icon',{attrs:{"small":"","color":"disabled"}},[_vm._v(" $IconArrowLeft ")])],1)],1):_vm._e(),(_vm.trigger.right)?_c('div',{class:[
            'trigger',
            _vm.$route.query.mail ? 'trigger__center' : 'trigger__right',
          ]},[_c('v-btn',{attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.scrollContainer(350)}}},[_c('v-icon',{attrs:{"small":"","color":"disabled"}},[_vm._v(" $IconArrowRight ")])],1)],1):_vm._e()]:_vm._e(),_vm._l((_vm.$props.data),function(item,index){return _c('div',{key:item?.id,ref:"upperItems",refInFor:true,class:[
          'v-container-box-column',
          'd-flex',
          'flex-column',
          (_vm.$route?.query?.filter === 'folder' ||
            _vm.$route?.query?.filter === 'box' ||
            _vm.$route?.query?.filter === 'trans') &&
            'v-container-box-column__horizontal',
        ]},[(
            !(
              _vm.$route?.query?.filter === 'folder' ||
              _vm.$route?.query?.filter === 'box' ||
              _vm.$route?.query?.filter === 'trans'
            )
          )?_c('div',{staticClass:"v-container-box-column-title"},[_c('div',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('v-divider'),_c('v-text-field',{attrs:{"placeholder":"Поиск","clearable":""},on:{"input":function($event){return _vm.$emit('changeSearch', item.id)}},model:{value:(_vm.$props.data[index].search),callback:function ($$v) {_vm.$set(_vm.$props.data[index], "search", $$v)},expression:"$props.data[index].search"}})],1):_vm._e(),_c('div',{staticClass:"v-container-box-column-items"},[(item?.mails?.rows && !item?.mails?.rows?.length)?[_vm._m(0,true)]:(item?.mails?.rows?.length)?_vm._l((item?.mails?.rows),function(mail,mailIndex){return _c('MailsLetter',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.getPagination),expression:"getPagination"}],key:mailIndex,ref:"lowerItems",refInFor:true,attrs:{"data":mail,"active":Number(_vm.$route.query.mail) === mail?.id,"tagsData":_vm.$props.filterData.tagsData,"selectedMails":_vm.selectedMails},on:{"setRouterPath":(add, remove, set) => _vm.$emit('setRouterPath', add, remove, set),"setActiveMail":($emit) => _vm.setActiveMail($emit, index, mailIndex),"getMails":function($event){return _vm.$emit('getMails')}}})}):[_c('div',{staticClass:"v-container-box-column-items_stub"},[_c('p',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)])]],2)])})],2):_vm._e(),(_vm.$route.query.mail || _vm.$route.query.compose === 'new')?_c('div',{class:[
        'v-container-expanded',
        _vm.$route.query.compose === 'new' && 'v-container-expanded__new',
        _vm.$route?.query?.mail && 'v-container-expanded__edited',
      ]},[_c('MailsLetterExpanded',{attrs:{"filterData":_vm.$props.filterData,"data":_vm.activeMail},on:{"setRouterPath":(add, remove, set) => _vm.$emit('setRouterPath', add, remove, set),"getMails":function($event){return _vm.$emit('getMails')}}})],1):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-container-box-column-items_stub"},[_c('p',[_vm._v("Нет писем")])])
}]

export { render, staticRenderFns }