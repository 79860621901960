var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['v-letter-expanded', 'd-flex', 'flex-column']},[(_vm.$props.data || _vm.$route?.query?.compose)?[_c('div',{staticClass:"v-letter-expanded-user"},[(!_vm.$route?.query?.compose)?[(
            _vm.$route.query.filter !== 'sent' && _vm.$route.query.filter !== 'trash'
          )?_c('v-icon',{staticClass:"v-letter-expanded-user_icon",attrs:{"color":_vm.$props?.data?.is_main ? 'warning' : '',"small":""},on:{"click":function($event){return _vm.$parent.$emit('changeMailKey', {
              id: _vm.$props.data.id,
              is_main: _vm.$props.data.is_main,
              box_id: _vm.$props?.data?.box_id,
              key: 'is_main',
            })}}},[_vm._v("$IconBookmark")]):_vm._e(),_c('MailsLetterUser',{attrs:{"data":_vm.$props.data,"expanded":""}}),_c('div',{staticClass:"v-letter-expanded-user-favorite"},[_c('MailsLetterDate',{attrs:{"data":_vm.$props.data}})],1)]:_c('MailsLetterUserEdit',{attrs:{"filterData":_vm.$props.filterData,"data":_vm.newMessage},on:{"deleteUser":_vm.deleteUser}})],2),_c('div',{staticClass:"v-letter-expanded-container"},[(_vm.$route?.query?.compose)?_c('MailsLetterTextEdit',{attrs:{"data":_vm.newMessage},on:{"addFiles":_vm.addFiles,"removeFile":_vm.removeFile}}):_vm._e(),(_vm.$route?.query?.compose !== 'new' && _vm.$props?.data)?_c('MailsLetterText',{attrs:{"data":_vm.$props?.data,"edit":!_vm.$route?.query?.compose || _vm.$route?.query?.compose === 'answer'}}):_vm._e()],1),(_vm.$route.query.filter !== 'sent' && _vm.$route.query.filter !== 'trash')?_c('div',{staticClass:"v-letter-expanded_btn"},[(!_vm.loading)?[_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.validation},on:{"click":function($event){_vm.$route?.query?.compose === 'new' ||
            _vm.$route?.query?.compose === 'answer'
              ? _vm.createMail()
              : _vm.answerToMail(_vm.$props.data)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$IconEdit")]),_vm._v(" "+_vm._s(_vm.$route?.query?.compose === 'new' || _vm.$route?.query?.compose === 'answer' ? 'Отправить' : 'Ответить')+" ")],1),(_vm.$route?.query?.compose !== 'new')?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"error"},on:{"click":_vm.closeLetter}},[_vm._v(" Закрыть ")]):_vm._e()]:_c('v-progress-circular',{staticClass:"ml-14 mb-1",attrs:{"indeterminate":"","color":"primary"}})],2):_vm._e()]:[_c('div',{staticClass:"v-letter-expanded_progress"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }