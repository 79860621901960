var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"v-controls"},[_c('div',{staticClass:"v-controls-list"},[_c('v-checkbox',{staticClass:"v-controls-list_item v-controls-list_item__input",attrs:{"color":"primary","value":_vm.$props.selectedAllMails,"disabled":_vm.$route.query.filter === 'sent' ||
        _vm.$route.query.filter === 'trash' ||
        _vm.$route.query.compose === 'new'},on:{"change":function($event){return _vm.$emit('changeSelection', 'all')}}}),_c('v-btn',{staticClass:"v-controls-list_item",attrs:{"color":"text","disabled":!_vm.$props.selectedMails.length,"plain":""},on:{"click":function($event){_vm.popupBroadcast = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.$props.selectedMails.length ? 'primary' : 'disabled',"small":""}},[_vm._v("$IconBroadcast")]),_vm._v(" Транслировать ")],1),_c('v-btn',{staticClass:"v-controls-list_item",attrs:{"disabled":!_vm.$props.selectedMails.length,"color":"text","plain":""},on:{"click":function($event){return _vm.$emit(
          'changeMailArrayKey',
          'is_read',
          _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
            ? _vm.$props.allSelectionFilter.is_read.value
            : _vm.intersection.read.length >= _vm.$props.selectedMails.length
            ? false
            : true
        )}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.$props.selectedMails.length ? 'primary' : 'disabled',"x-small":""}},[_vm._v("$IconCheckoutMessage")]),_vm._v(" Не прочитаны ")],1),_c('v-btn',{staticClass:"v-controls-list_item",attrs:{"id":"menu-activator","color":"text","plain":"","disabled":!_vm.$props.selectedMails.length}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.$props.selectedMails.length ? 'primary' : 'disabled',"small":""}},[_vm._v("$IconSystem")]),_vm._v(" В папку ")],1),(_vm.intersection.foldersCount?.length && _vm.$props.selectedMails.length)?_c('v-menu',{attrs:{"content-class":"v-controls-list_menu","activator":"#menu-activator","close-on-content-click":false}},[_c('v-list',_vm._l((_vm.intersection.foldersCount),function(item){return _c('v-list-item',{key:item.id,class:[
            'v-controls-list_menu-item',
            _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
              ? _vm.$props.allSelectionFilter.folders.find(
                  (x) => x.id === item.id
                ).value && 'v-controls-list_menu-item__active'
              : _vm.intersection.folders.includes(String(item.id)) &&
                'v-controls-list_menu-item__active',
          ],on:{"click":function($event){_vm.$emit(
              'changeMailArrayKey',
              'folders',
              item,
              _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
                ? _vm.$props.allSelectionFilter.folders.find(
                    (x) => x.id === item.id
                  ).value
                : _vm.intersection.folders.includes(String(item.id))
            )}}},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.$props.selectedAllMails ? `(${ _vm.$props.allSelectionFilter.folders.find( (x) => x.id === item.id ).count } / ${_vm.$props.allMails.count})` : `(${ _vm.intersection.foldersCount.find((x) => x.id === item.id) .value } / ${_vm.$props.selectedMails.length})`)+" "),(
              _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
                ? _vm.$props.allSelectionFilter.folders.find(
                    (x) => x.id === item.id
                  ).value
                : _vm.intersection.folders.includes(String(item.id))
            )?_c('v-icon',{staticClass:"ml-3",attrs:{"color":"text","small":""}},[_vm._v("$IconDelete")]):_vm._e()],1)}),1)],1):_vm._e(),_c('v-btn',{staticClass:"v-controls-list_item",attrs:{"id":"tags-activator","color":"text","plain":"","disabled":!_vm.$props.selectedMails.length}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.$props.selectedMails.length ? 'primary' : 'disabled',"small":""}},[_vm._v("$IconTag")]),_vm._v(" Тэг ")],1),(
        _vm.$props?.filterData?.tagsData?.length && _vm.$props.selectedMails.length
      )?_c('v-menu',{attrs:{"content-class":"v-controls-list_tags","activator":"#tags-activator","close-on-content-click":false}},[_c('v-list',_vm._l((_vm.$props.filterData.tagsData),function(item){return _c('v-list-item',{key:item.id,staticClass:"v-controls-list_tags-item",on:{"click":function($event){_vm.$emit(
              'changeMailArrayKey',
              'tags',
              item,
              _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
                ? _vm.$props.allSelectionFilter.tags.find((x) => x.id === item.id)
                    .value
                : _vm.intersection.tags.includes(String(item.id))
            )}}},[_c('div',{class:[
              'v-controls-list_tags-item-tag',
              _vm.$props.selectedAllMails && !_vm.$props.allMails.loadAll
                ? _vm.$props.allSelectionFilter.tags.find((x) => x.id === item.id)
                    .value && 'v-controls-list_tags-item-tag__active'
                : _vm.intersection.tags.includes(String(item.id)) &&
                  'v-controls-list_tags-item-tag__active',
            ],style:({ background: item.color })}),_vm._v(" "+_vm._s(_vm.$props.selectedAllMails ? `${ _vm.$props.allSelectionFilter.tags.find((x) => x.id === item.id) .count }/${_vm.$props.allMails.count}` : `${ _vm.intersection.tagsCount.find((x) => x.id === item.id).value }/${_vm.$props.selectedMails.length}`)+" ")])}),1)],1):_vm._e(),_c('v-btn',{staticClass:"v-controls-list_item",attrs:{"disabled":!_vm.$props.selectedMails.length,"color":"text","plain":""},on:{"click":function($event){_vm.popupDelete = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":_vm.$props.selectedMails.length ? 'primary' : 'disabled',"small":""}},[_vm._v("$IconDelete")]),_vm._v(" Удалить ")],1)],1),(_vm.popupBroadcast)?_c('Popup',{attrs:{"options":{
      portal: 'filter',
      padding: '20px 30px',
      width: '900px',
    }},on:{"close":function($event){_vm.popupBroadcast = false}}},[_c('div',{staticClass:"v-controls-popup d-flex flex-column"},[_c('p',{staticClass:"v-controls-popup_title text-center"},[_vm._v("Транслировать письма")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{staticClass:"mt-4",attrs:{"items":_vm.broadcast.path.items,"item-text":"name","item-value":"value","outlined":"","label":"Кому транслировать"},on:{"change":function($event){return _vm.changeKey(_vm.broadcast.path.name)}},model:{value:(_vm.broadcast.path.value),callback:function ($$v) {_vm.$set(_vm.broadcast.path, "value", $$v)},expression:"broadcast.path.value"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[(_vm.broadcast.path.value === 'direction')?_c('v-select',{attrs:{"items":_vm.broadcast.direction.items,"menu-props":{ maxHeight: '400' },"label":"Выберите направление","item-text":"name","item-value":"id"},on:{"change":function($event){return _vm.getItems({
                val: ['otdel', 'object', 'account'],
                resetAcc: true,
              })}},model:{value:(_vm.broadcast.direction.value),callback:function ($$v) {_vm.$set(_vm.broadcast.direction, "value", $$v)},expression:"broadcast.direction.value"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":_vm.broadcast.path.value === 'direction' ? 6 : 12}},[(_vm.broadcast.path.value !== 'account')?_c('v-autocomplete',{attrs:{"items":_vm.broadcast.otdel.items,"menu-props":{ maxHeight: '400' },"label":"Выберите подразделение","search-input":_vm.broadcast.otdel.search,"no-data-text":"Нет подразделение","multiple":_vm.broadcast.otdel.multiple,"hide-selected":"","chips":"","clearable":"","disabled":(_vm.broadcast.path.value === 'direction' &&
                !_vm.broadcast.direction.value) ||
              true,"deletable-chips":"","item-text":"name","item-value":"id"},on:{"update:searchInput":function($event){return _vm.$set(_vm.broadcast.otdel, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.broadcast.otdel, "search", $event)},"change":function($event){return _vm.getItems({ val: ['account'], resetAcc: true })}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}],attrs:{"id":"otdel"}})]},proxy:true}],null,false,3470924043),model:{value:(_vm.broadcast.otdel.value),callback:function ($$v) {_vm.$set(_vm.broadcast.otdel, "value", $$v)},expression:"broadcast.otdel.value"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[(_vm.broadcast.path.value === 'direction')?_c('v-autocomplete',{staticClass:"v-controls-popup-item__cutted",attrs:{"items":_vm.broadcast.object.items,"menu-props":{ maxHeight: '400' },"label":"Выберите объект","no-data-text":"Нет объектов","search-input":_vm.broadcast.object.search,"multiple":_vm.broadcast.object.multiple,"hide-selected":"","chips":"","clearable":"","deletable-chips":"","disabled":_vm.broadcast.path.value === 'direction' &&
              !_vm.broadcast.direction.value,"item-text":"address","item-value":"id"},on:{"update:searchInput":function($event){return _vm.$set(_vm.broadcast.object, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.broadcast.object, "search", $event)},"change":function($event){return _vm.getItems({ val: ['account'], resetAcc: true })}},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}],attrs:{"id":"object"}})]},proxy:true}],null,false,3854187752),model:{value:(_vm.broadcast.object.value),callback:function ($$v) {_vm.$set(_vm.broadcast.object, "value", $$v)},expression:"broadcast.object.value"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-autocomplete',{attrs:{"value":_vm.broadcast.account.value,"items":_vm.broadcast.account.items,"menu-props":{ maxHeight: '400' },"label":"Выберите пользователей","no-data-text":"Нет пользователей","hide-selected":"","search-input":_vm.broadcast.account.search,"multiple":_vm.broadcast.account.multiple,"filter":_vm.accountFilter,"chips":"","clearable":"","deletable-chips":"","disabled":(_vm.broadcast.path.value === 'otdel' && !_vm.broadcast.otdel.value) ||
              (_vm.broadcast.path.value === 'direction' &&
                !_vm.broadcast.direction.value),"item-value":"id","item-text":"fio"},on:{"update:searchInput":function($event){return _vm.$set(_vm.broadcast.account, "search", $event)},"update:search-input":function($event){return _vm.$set(_vm.broadcast.account, "search", $event)},"change":_vm.checkAll,"blur":function($event){_vm.broadcast.account.search = ''}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('div',{staticClass:"d-flex flex-column"},[_c('p',[_vm._v(_vm._s(item.fio))]),_c('p',{staticClass:"v-controls-popup_subtitle"},[_vm._v(_vm._s(item.doljnost))])])]}},{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}],attrs:{"id":"account"}})]},proxy:true}],null,false,3876326848),model:{value:(_vm.broadcast.account.value),callback:function ($$v) {_vm.$set(_vm.broadcast.account, "value", $$v)},expression:"broadcast.account.value"}})],1)],1),_c('div',{staticClass:"d-flex mt-9 justify-center"},[_c('v-btn',{attrs:{"tonal":"","disabled":!_vm.broadcast.account.value,"color":"primary"},on:{"click":_vm.broadcastLetters}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$IconBroadcast")]),_vm._v(" Транслировать ")],1),_c('v-btn',{staticClass:"ml-8",attrs:{"tonal":"","color":"error"},on:{"click":function($event){_vm.popupBroadcast = false}}},[_vm._v(" Отменить ")])],1)],1)]):_vm._e(),(_vm.popupDelete)?_c('Popup',{attrs:{"options":{ portal: 'filter', padding: '20px 30px' },"closeButton":""},on:{"close":function($event){_vm.popupDelete = false}}},[_c('div',{staticClass:"v-controls-popup d-flex flex-column"},[_c('p',{staticClass:"v-controls-popup_title text-center"},[_vm._v("Удалить письма?")]),_c('div',{staticClass:"d-flex mt-7"},[_c('v-btn',{attrs:{"tonal":"","color":"error"},on:{"click":function($event){_vm.$emit('changeMailArrayKey', 'del', true)
            _vm.popupDelete = false}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$IconDelete")]),_vm._v(" Удалить ")],1),_c('v-btn',{staticClass:"ml-5",attrs:{"tonal":"","color":"primary"},on:{"click":function($event){_vm.popupDelete = false}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("$IconArrowCansel")]),_vm._v(" Отменить ")],1)],1)])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }