var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding":"10px"}},[_c('v-card-title',{staticClass:"py-1 justify-center"},[_vm._v(" Назначение "),_c('span',{staticClass:"col-btn form-link",on:{"click":function($event){return _vm.pushToForm(_vm.entity.id)}}},[_vm._v(" №"+_vm._s(_vm.entity.id)+" ")]),_vm._v(" на дату "+_vm._s(_vm.dateTarget)+" ")]),_c('TextInfo',{attrs:{"infoObj":_vm.infoObj}})],1),_c('v-divider'),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.loading,"small":"","color":"info"},on:{"click":_vm.confirm}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1),_c('v-btn',{attrs:{"loading":_vm.loading,"small":"","color":"error"},on:{"click":_vm.reject}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Отклонить ")],1)],1),(
      _vm.proxyConfig.detail &&
      _vm.proxyConfig.detail.type === 'popup' &&
      _vm.popupForm.isShow
    )?_c(_vm.Popup,{tag:"component",attrs:{"options":{
      width: _vm.proxyConfig.detail.width,
      portal: 'table-detail',
    }}},[_c('router-view',{class:[
        ..._vm.proxyConfig.detail.bootstrapClass,
        ..._vm.proxyConfig.detail.classes,
      ],attrs:{"detail":_vm.proxyConfig.detail},on:{"closePopup":_vm.closePopupForm}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }