var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding-top":"20px"}},[_c('PersTitle',{attrs:{"data":{
        surname: _vm.data.entity.surname,
        name_n: _vm.data.entity.name_n,
        patronymic: _vm.data.entity.patronymic,
        dataRojd: _vm.data.entity.data_rojd.split('-').reverse().join('.'),
      }}}),_c('TextInfo',{staticClass:"mb-3",attrs:{"infoObj":_vm.textInfo}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v("Создайте расход на документы:")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{attrs:{"small":"","color":"info"},on:{"click":_vm.pushToZayavka}},[_vm._v(" "+_vm._s(_vm.hasRashod ? 'Изменить' : 'Создать')+" ")])],1)])],1),_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"mb-10",class:[!_vm.hasRashod ? 'overflow-inputs' : '']},[(_vm.listDocuments && _vm.listDocuments.length)?_c('DocForm',{ref:"docFormRef",attrs:{"docsData":_vm.listDocuments,"listNames":_vm.listNames,"docs":_vm.listDocuments,"entity":_vm.data.entity,"task":_vm.data.task,"title":"Приложите документы:","showFields":false,"showDropzone":true,"withoutSave":true,"fromTask":true},on:{"changeDocs":_vm.changeDocs}}):_vm._e()],1)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{attrs:{"small":"","color":"success","disabled":!_vm.canAttach},on:{"click":_vm.sendDocuments}},[_vm._v(" Приложить ")])],1)])],1),_vm._m(0),_c('v-row',{class:[!_vm.allDocsAttached || !_vm.hasRashod ? 'overflow-inputs' : '']},[_c('v-col',{class:[
          _vm.listDisbledDocuments > 0 && _vm.data.data.need_patent
            ? 'overflow-inputs'
            : '',
        ],attrs:{"cols":"6"}},[_c('Dropzone',{attrs:{"options":{
            withoutSave: false,
            folder: 'tmp',
            removeble: false,
          },"paramsForEmit":{ item: 5 }},on:{"addFiles":_vm.addFilesPatent}})],1),_c('v-col',{class:[
          _vm.listDisbledDocuments > 0 && _vm.data.data.need_patent
            ? 'overflow-inputs'
            : '',
        ],attrs:{"cols":"6"}},[_c('Dropzone',{attrs:{"options":{
            withoutSave: false,
            folder: 'tmp',
            removeble: false,
          },"paramsForEmit":{ item: 15 }},on:{"addFiles":_vm.addFilesPatent}})],1)],1),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{attrs:{"small":"","color":"info","disabled":!_vm.isValid},on:{"click":_vm.sendTaskFinish}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1)],1),(
        _vm.config.detail && _vm.config.detail.type === 'popup' && _vm.popupForm.isShow
      )?_c(_vm.Popup,{tag:"component",attrs:{"options":{
        width: _vm.config.detail.width,
        portal: 'table-detail',
      }}},[_c('router-view',{class:[..._vm.config.detail.bootstrapClass, ..._vm.config.detail.classes],attrs:{"detail":_vm.config.detail},on:{"closePopup":_vm.closePopupForm,"refreshData":function($event){return _vm.$emit('refreshData')}}})],1):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Патент:")])])
}]

export { render, staticRenderFns }