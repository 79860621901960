import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import { required, hasDate, hasTime, nameLength } from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import FormOutput from '@/components/Form/output/index.vue'
import Vue, { toRef, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'
import store from '@/store'

export default {
  path: 'tarif_parser',
  id: uuidv4(),
  name: 'Парсер реестра',
  type: FormOutput,
  detail: true,
  initialRequestUrl: 'get/parser/active/',
  lastStage: 'TarifStage',
  outputType: 5,
  lists: [
    { alias: 'x5_territories', filter: [] },
    { alias: 'contract_object_types', filter: [] },
    { alias: 'magnit_agreement', filter: [] },
    { alias: 'chigik_agreement', filter: [] },
  ],
  fields: [
    selectField({
      label: 'Тип объекта',
      name: 'contract_object_types',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      position: {
        cols: 12,
        sm: 12,
      },
      dependence: [
        {
          type: 'custom',
          func: (ctx) => {
            if (ctx.formData.contract_object_types === 1) {
              ctx.formData.magnit_agreement = null
              ctx.formData.chigik_agreement = null
            } else if (ctx.formData.contract_object_types === 2) {
              ctx.formData.agreement = null
              ctx.formData.chigik_agreement = null
            } else if (ctx.formData.contract_object_types === 3) {
              ctx.formData.agreement = null
              ctx.formData.magnit_agreement = null
            }
            // ctx.formData.territory_id = ''
            // ctx.formData.agreement = ''
            // ctx.formData.magnit_agreement = ''
            // ctx.formData.agreement = ''
            // ctx.formData.type_contract_id = ''
            // ctx.formData.contract_id = ''
            // ctx.formData.application_id = ''
            // ctx.formData.contract_type = ''
            // ctx.formData.type_id = ''
            // ctx.formData.agreement_id = ''
          },
        },
      ],
      validations: { required },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'Территория',
      name: 'territory_id',
      alias: 'x5_territories',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      position: {
        cols: 12,
        sm: 12,
      },
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'agreement',
          url: 'get/pagination_list/territories_agreement',
        },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types === 1
            },
          },
        ],
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    autocompleteField({
      label: 'Договор Х5',
      name: 'agreement',
      // requestKey: 'agreement_id',
      subtype: 'single',
      placeholder: '',
      notSend: true,
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      page: 1,
      search: '',
      notPutSingle: true,
      url: 'get/pagination_list/territories_agreement',
      filter: [
        {
          field: 'territory_id',
          value: '',
        },
      ],
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'type_contract_id',
          url: 'get/pagination_list/versions_agreement',
        },
        // {
        //   type: 'default',
        //   fillField: ['type_id'],
        // },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types === 1
            },
          },
        ],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'Договор магнит',
      name: 'magnit_agreement',
      subtype: 'single',
      placeholder: '',
      notSend: true,
      class: [''],
      notPutSingle: true,
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'type_contract_id',
          url: 'get/pagination_list/versions_agreement',
        },
        // {
        //   type: 'default',
        //   fillField: ['agreement_id'],
        // },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types === 2
            },
          },
        ],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'Договор чижик',
      name: 'chigik_agreement',
      subtype: 'single',
      placeholder: '',
      notSend: true,
      class: [''],
      notPutSingle: true,
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'type_contract_id',
          url: 'get/pagination_list/versions_agreement',
        },
        // {
        //   type: 'default',
        //   fillField: ['agreement_id'],
        // },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types === 3
            },
          },
        ],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    autocompleteField({
      label: 'Версия',
      name: 'type_contract_id',
      subtype: 'single',
      placeholder: '',
      notSend: true,
      class: [''],
      selectOption: {
        text: 'name',
        value: 'contract_id',
      },
      items: [],
      page: 1,
      search: '',
      url: 'get/pagination_list/versions_agreement',
      position: {
        cols: 12,
        sm: 12,
      },
      filter: [
        {
          field: 'agreement',
          alias: 'agreement_id',
          value: '',
        },
        {
          field: 'magnit_agreement',
          alias: 'agreement_id',
          value: '',
        },
        {
          field: 'chigik_agreement',
          alias: 'agreement_id',
          value: '',
        },
      ],
      dependence: [
        {
          type: 'default',
          fillField: [
            'contract_id',
            'contract_type',
            'type_id',
            'agreement_id',
          ],
        },
        {
          type: 'custom',
          func: (ctx) => {
            if (ctx.formData.contract_object_types === 2) {
              ctx.formData.application_id = ctx.formData.contract_id
            }
          },
        },
      ],
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types
            },
          },
        ],
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'contract_id',
      name: 'contract_id',
      requestType: 'number',
      placeholder: '',
      class: ['hidden'],
      disabled: true,
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return (
                ctx.formData.contract_object_types === 1 ||
                ctx.formData.contract_object_types === 3
              )
            },
          },
        ],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'application_id',
      name: 'application_id',
      requestType: 'number',
      placeholder: '',
      class: ['hidden'],
      disabled: true,
      isShow: {
        value: false,
        conditions: [
          {
            target: 'funcCondition',
            funcCondition: (ctx) => {
              return ctx.formData.contract_object_types === 2
            },
          },
        ],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'contract_type',
      name: 'contract_type',
      requestKey: 'type_contract_id',
      requestType: 'number',
      placeholder: '',
      class: ['hidden'],
      disabled: true,
      // isShow: {
      //   value: true,
      // },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'type_id',
      name: 'type_id',
      requestType: 'number',
      requestKey: 'type_parser',
      placeholder: '',
      class: ['hidden'],
      disabled: true,
      // isShow: {
      //   value: true,
      // },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'agreement_id',
      name: 'agreement_id',
      // requestKey: 'agreement_id',
      requestType: 'number',
      placeholder: '',
      class: ['hidden'],
      disabled: true,
      // isShow: {
      //   value: false,
      //   conditions: [
      //     {
      //       target: 'funcCondition',
      //       funcCondition: (ctx) => {
      //         return ctx.formData.contract_object_types === 2
      //       },
      //     },
      //   ],
      // },
      // isShow: {
      //   value: true,
      // },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    dropZoneField({
      label: 'Файл',
      name: 'file',
      notPut: true,
      placeholder: '',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      bootstrapClass: [''],
      validations: { required },
      options: {
        removeble: true,
        withoutSave: false,
        maxSize: 500,
        type: ['xlsx'],
        valueId: 'parser_tarif',
        folder: 'parser',
        name: '`parser_tarif`',
        paramsForEmit: this,
        countFiles: 1,
      },
      value: [],
    }),
    checkboxField({
      name: 'reparse',
      value: false,
      placeholder: '',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      disabled: true,
      isShow: {
        value: true,
      },
      bootstrapClass: [''],
    }),
  ],
  outputData: {
    file: {
      text: 'По файлу:',
      value: null,
      stage: 1,
    },
    search: {
      text: 'Найдено:',
      value: null,
      stage: 1,
    },
    error: {
      text: 'С ошибками:',
      value: null,
      stage: 1,
    },
    count: {
      text: 'Кол-во:',
      value: null,
      stage: 2,
    },
    sum: {
      text: 'Сумма:',
      value: null,
      stage: 2,
    },
  },
  stageActions: [
    {
      actions: [
        stringAction({
          text: 'Закрыть',
          color: 'error',
          name: 'closePopup',
          action: 'closePopup',
          skipValidation: true,
        }),
        stringAction({
          text: 'Сохранить',
          type: 'submit',
          color: 'primary',
          name: 'saveFormStore',
          action: 'saveFormStore',
          notClose: true,
          module: 'form/loadParser',
          url: 'load/parser/',
        }),
      ],
    },
    {
      actions: [
        stringAction({
          text: 'Закрыть',
          color: 'error',
          name: 'closePopup',
          action: 'closePopup',
          skipValidation: true,
        }),
        stringAction({
          text: 'К выработке',
          type: 'submit',
          color: 'primary',
          confirm: {
            text: '`Вы подтверждаете переход к выработке?`',
            width: 550,
          },
          action: 'changeStage',
          changeDirection: 1,
          local: true,
        }),
      ],
    },
    {
      actions: [
        stringAction({
          text: 'Вернуться',
          color: 'error',
          action: 'changeStage',
          changeDirection: -1,
          local: true,
        }),
        stringAction({
          text: 'Отправить',
          type: 'submit',
          color: 'primary',
          confirm: {
            text: '`Подтвердите действие`',
            width: 350,
          },
          action: 'loadParser',
          local: true,
        }),
      ],
    },
  ],
}
