var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'v-letter',
    _vm.$props.active && 'v-letter__active',
    (_vm.$route.query.filter === 'folder' ||
      _vm.$route.query.filter === 'box' ||
      _vm.$route?.query?.filter === 'trans') &&
      !_vm.$route.query.mail &&
      'v-letter__wide',
  ]},[_c('div',{staticClass:"v-letter-trigger",on:{"click":function($event){return _vm.$emit('setActiveMail', _vm.$props.data)}}}),_c('div',{staticClass:"v-letter-left"},[(_vm.$route.query.filter !== 'sent' && _vm.$route.query.filter !== 'trash')?_c('v-icon',{staticClass:"v-letter-left_icon",attrs:{"color":_vm.$props?.data?.is_main ? 'warning' : '',"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.$parent.$emit('changeMailKey', {
          id: _vm.$props.data.id,
          is_main: _vm.$props.data.is_main,
          box_id: _vm.$props?.data?.box_id,
          key: 'is_main',
        })}}},[_vm._v("$IconBookmark")]):_vm._e(),(_vm.$route.query.filter !== 'sent' && _vm.$route.query.filter !== 'trash')?_c('v-checkbox',{staticClass:"v-letter-left_checkbox",attrs:{"color":"primary","value":_vm.$props?.selectedMails?.includes(_vm.$props.data.id)},on:{"click":function($event){$event.stopPropagation();},"change":function($event){return _vm.$parent.$emit('changeSelection', _vm.$props.data.id)}},model:{value:(_vm.checkbox),callback:function ($$v) {_vm.checkbox=$$v},expression:"checkbox"}}):_vm._e(),_c('div',{staticClass:"v-letter-left_color-mark",style:({
        backgroundColor: _vm.$props?.data?.color_box || 'var(--v-primary-base)',
        marginTop:
          _vm.$route.query.filter !== 'sent' && _vm.$route.query.filter !== 'trash'
            ? '12px'
            : '0px',
      })})],1),_c('div',{staticClass:"v-letter-content"},[_c('div',{staticClass:"v-letter-content-user"},[_c('MailsLetterUser',{attrs:{"data":_vm.$props.data}})],1),_c('div',{staticClass:"v-letter-content-favorite"},[_c('MailsLetterDate',{attrs:{"data":_vm.$props.data}})],1),_c('div',{staticClass:"v-letter-content-info"},[_c('p',{staticClass:"v-letter-content-info_title"},[_vm._v(" "+_vm._s(_vm.$props.data.subject)+" ")]),_c('iframe',{ref:"iframe",staticClass:"v-letter-content-info_text",attrs:{"frameborder":"0"}})]),_c('div',{staticClass:"v-letter-content-files"},[_c('MailsLetterFiles',{attrs:{"data":_vm.$props?.data}})],1),_c('div',{staticClass:"v-letter-content-tags"},[(_vm.$props?.data?.tags)?_vm._l((JSON.parse(_vm.$props?.data?.tags)),function(item,index){return _c('div',{key:index,staticClass:"v-letter-content-tags_item",style:({
            background: _vm.$props?.tagsData?.find((x) => x.id === Number(item))
              ?.color,
          }),on:{"click":function($event){$event.stopPropagation();return _vm.setActiveColorFilter(item)}}})}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }