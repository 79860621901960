import Vue, {
  nextTick,
  computed,
  ref,
  onMounted,
  watch,
  onUnmounted,
} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'

import useRequest from '@/compositions/useRequest'

import _ from 'lodash'
import moment from 'moment'
import { getList } from '@/api/selects'

import store from '@/store'

export default {
  name: 'Form-Output',
  components: {},
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const rates = [
      { name: 'База', key: 'base_rate' },
      { name: 'I', key: '1_rate' },
      { name: 'II', key: '2_rate' },
      { name: 'III', key: '3_rate' },
      { name: 'IV', key: '4_rate' },
    ]
    const object = ref({
      isShow: false,
      zone: null,
      territory: null,
      page: 1,
      items: [],
      loading: false,
      totalPage: null,
      search: '',
    })

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: async (data) => {
        const response = await store.dispatch('list/get', data)
        return response
      },
    })

    const parserFilter = {
      alias: 'parser_id',
      value: [props.stage.outputId],
      type: 'num',
    }

    const territories = ref([])
    const territoriesModel = ref([])

    const getTerritories = async () => {
      const response = await makeRequestList([
        {
          alias: 'parser_x5_territories',
          filter: [parserFilter],
        },
      ])
      response.data.parser_x5_territories.forEach((item) => {
        Vue.set(item, 'data', {
          loaded: null,
          zones: [],
          zonesModel: [],
        })
      })
      territories.value = response.data.parser_x5_territories
      territories.value.forEach((item) => {
        watch(
          () => item.data.zonesModel,
          (newVal, oldVal) => {
            let index
            if (newVal.length > oldVal.length)
              index = _.difference(newVal, oldVal)[0]
            else index = _.difference(oldVal, newVal)[0]
            if (index !== undefined && !item.data.zones[index]?.data.loaded)
              getServices(index, item)
          },
          { deep: true }
        )
      })
    }

    const getServices = async (index, territory) => {
      const zone = territory.data.zones[index]
      if (zone.data.loaded !== true)
        territory.data.zonesModel = _.without(territory.data.zonesModel, index)
      if (zone.data.loaded !== null) return
      zone.data.loaded = false
      const response = await makeRequestList([
        {
          alias: 'parser_x5_services',
          filter: [
            parserFilter,
            {
              alias: 'territory_id',
              value: [territory.id],
              type: 'num',
            },
            {
              alias: 'zone_id',
              value: [zone.id],
              type: 'num',
            },
          ],
        },
      ])
      response.data.parser_x5_services.forEach((item) => {
        Vue.set(item, 'data', {
          loaded: null,
          info: null,
        })
      })
      zone.data.services = response.data.parser_x5_services
      zone.data.loaded = true
      territory.data.zonesModel.push(index)
    }

    const getZones = async (index) => {
      const territory = territories.value[index]
      if (territory.data.loaded !== true)
        territoriesModel.value = _.without(territoriesModel.value, index)
      if (territory.data.loaded !== null) return
      territory.data.loaded = false
      const response = await makeRequestList([
        {
          alias: 'parser_x5_zone',
          filter: [
            parserFilter,
            {
              alias: 'territory_id',
              value: [territory.id],
              type: 'num',
            },
          ],
        },
      ])
      response.data.parser_x5_zone.forEach((item) => {
        Vue.set(item, 'data', {
          loaded: null,
          services: [],
          servicesModel: [],
        })
      })
      territory.data.zones = response.data.parser_x5_zone
      territory.data.loaded = true
      territoriesModel.value.push(index)
      territory.data.zones.forEach((item) => {
        watch(
          () => item.data.servicesModel,
          (newVal, oldVal) => {
            let index
            if (newVal.length > oldVal.length)
              index = _.difference(newVal, oldVal)[0]
            else index = _.difference(oldVal, newVal)[0]
            if (index !== undefined && !item.data.services[index]?.data.loaded)
              getTargetService(index, item)
          },
          { deep: true }
        )
      })
    }

    const getTargetService = async (index, zone) => {
      const service = zone.data.services[index]
      if (service.data.loaded !== true)
        zone.data.servicesModel = _.without(zone.data.servicesModel, index)
      if (service.data.loaded !== null) return
      service.data.loaded = false
      const response = await Promise.all(
        [1, 2].reduce((acc, item) => {
          acc.push(
            makeRequestList([
              {
                alias: 'parser_x5_coefficient',
                filter: [
                  parserFilter,
                  {
                    alias: 'tarif_id',
                    value: [service.id],
                    type: 'num',
                  },
                  {
                    alias: 'type_id',
                    value: [item],
                    type: 'num',
                  },
                ],
              },
            ])
          )
          return acc
        }, [])
      )
      service.data.info = response.reduce((acc, item) => {
        acc.push(item.data.parser_x5_coefficient)
        return acc
      }, [])
      service.data.loaded = true
      zone.data.servicesModel.push(index)
    }

    watch(
      () => territoriesModel.value,
      (newVal, oldVal) => {
        let index
        if (newVal.length > oldVal.length)
          index = _.difference(newVal, oldVal)[0]
        else index = _.difference(oldVal, newVal)[0]
        if (index !== undefined && !territories.value[index]?.data.loaded)
          getZones(index)
      },
      { deep: true }
    )

    const convertData = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    onMounted(() => {
      getTerritories()
    })

    const getZoneObjects = async (territory, zone) => {
      object.value.isShow = true
      object.value.territory = territory
      object.value.zone = zone
      clearObjectsData()
      clearSearch()
      await getPagination()
    }

    const clearObjectsData = () => {
      object.value.page = 1
      object.value.totalPage = null
      object.value.loading = false
      object.value.items = []
    }

    const clearSearch = () => {
      object.value.search = ''
    }

    let controller
    const getPagination = async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      object.value.loading = true
      const data = await getList(
        'get/pagination_list/parser_x5_objects',
        {
          countRows: 20,
          currentPage: object.value.page,
          searchValue: object.value.search,
          searchColumns: [],
          sorts: [],
          id: -1,
          filter: [
            {
              alias: 'zone_id',
              value: object.value.zone.id,
            },
            {
              alias: 'territory_id',
              value: object.value.territory.id,
            },
          ],
        },
        {
          signal: controller.signal,
        }
      )
      object.value.page++
      object.value.items = [...object.value.items, ...data.rows]
      if (data.totalPage >= object.value.page) {
        Vue.set(object.value.items.at(-1), 'intersecting', true)
      }
      object.value.loading = false
      controller = undefined
    }

    watch(
      () => object.value.search,
      async () => {
        clearObjectsData()
        await getPagination()
      }
    )

    return {
      rates,
      object,
      convertData,
      territories,
      territoriesModel,
      getZoneObjects,
      getPagination,
    }
  },
}
