var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding":"8px"}},[_c('PersTitle',{attrs:{"data":{
        surname: _vm.data.entity.surname,
        name_n: _vm.data.entity.name_n,
        patronymic: _vm.data.entity.patronymic,
        dataRojd: _vm.data.entity.data_rojd.split('-').reverse().join('.'),
      }}}),_c('TextInfo',{staticClass:"mb-3",attrs:{"infoObj":_vm.textInfo}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}})],1),_c('div',{staticClass:"mb-7"},[(_vm.listDocuments && _vm.listDocuments.length)?_c('DocForm',{ref:"docFormRef",attrs:{"docsData":_vm.listDocuments,"listNames":_vm.data.data.docs_spr,"docs":_vm.listDocuments,"entity":_vm.data.entity,"task":_vm.data.task,"title":"Приложите документы:","showFields":false,"showDropzone":true,"withoutSave":true,"fromTask":true},on:{"changeDocs":_vm.changeDocs}}):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{attrs:{"small":"","color":"success","disabled":!_vm.canAttach},on:{"click":_vm.sendDocuments}},[_vm._v(" Приложить ")])],1)])],1),_c('div',{},[_c('span',[_vm._v("Закрывающие документы:")]),_vm._l((_vm.data.data.zayavka.close_schet),function(item,index){return _c('DocAccepting',{key:index,attrs:{"docName":item.name,"docs":item,"hideActions":true},on:{"confirmed":_vm.addConfirmed,"unconfirmed":_vm.addUnconfirmed}})})],2),_vm._m(0),_c('v-row',[_c('Dropzone',{ref:"clearDropzone",attrs:{"options":{
          withoutSave: false,
          folder: 'tmp',
          removeble: false,
          countFiles: 20,
        },"paramsForEmit":{}},on:{"addFiles":_vm.addFilesPatent}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-btn',{attrs:{"small":"","color":"success","disabled":!_vm.isSetFilesCloseSchet},on:{"click":_vm.sendCloseDocsSchet}},[_vm._v(" Приложить ")])],1)])],1),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{attrs:{"small":"","color":"info","loading":_vm.loading,"disabled":!_vm.docsAttached || !_vm.data.data.zayavka.close_schet.length},on:{"click":_vm.sendTaskFinish}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-8"},[_c('span',[_vm._v("Приложите закрывающие документы")])])
}]

export { render, staticRenderFns }