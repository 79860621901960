import Vue, { ref, onMounted, computed } from 'vue'
import _ from 'lodash'
import { useStore } from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
// import MailsLetterExpanded from '../letter/expanded/index.vue'
// import Card from '../card/index.vue'

export default {
  name: 'Cards-Item',
  components: {
    // Card,
  },
  props: {},
  setup(props, context) {
    return {}
  },
}
