import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import changeMenu from '../changeMenu'
import useRequest from '@/compositions/useRequest'
import action from './action'
import plug from '@/components/Contract/plug'

export default defineComponent({
  props: {
    id: {
      type: Number,
      default: 0,
    },
    entity: {
      type: String,
      default: 'tariff',
    },
  },
  components: {
    action,
    plug,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const isShow = ref(false)

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }
    const convertDateExternal = (val) => {
      return moment(val, 'YYYY-MM-DD hh:mm:ss').format('DD.MM.YYYY hh:mm:ss')
    }

    const actionsArray = [
      {
        tooltip: 'Редактировать',
        icon: 'mdi-pencil-outline',
        type: 'edit',
      },
      {
        tooltip: 'Пролонгировать',
        icon: 'mdi-file-document-outline',
        type: 'prolongation',
      },
      {
        tooltip: 'Отключить',
        icon: 'mdi-power',
        type: 'off',
      },
    ]

    const items = ref([])
    const page = ref(0)
    const total = ref(null)
    const loading = ref(false)
    const isActive = ref(false)

    let controller
    const getItems = async () => {
      if (total.value && page.value >= total.value) return
      if (controller) controller.abort()
      try {
        controller = new AbortController()
        loading.value = true
        page.value++

        const response = await getList(
          'get/pagination_list/history_x5_object_price',
          {
            countRows: 20,
            currentPage: page.value,
            searchValue: '',
            id: -1,
            filter: [
              {
                alias: 'is_active',
                value: !isActive.value,
              },
              {
                alias: `${props.entity}_id`,
                value: props.id,
              },
            ],
            readonly: 0,
          },
          {
            signal: controller.signal,
          }
        )
        if (response.rows.length) {
          Vue.set(response.rows.at(-1), 'intersecting', true)
        }
        total.value = response.totalPage
        items.value = [...items.value, ...response.rows]
      } finally {
        loading.value = false
        controller = undefined
      }
    }

    const refreshItems = () => {
      items.value = []
      page.value = 0
      total.value = null
    }

    const initItems = () => {
      refreshItems()
      getItems()
    }

    watch(
      () => isShow.value,
      () => {
        if (isShow.value) {
          initItems()
        }
      }
    )

    return {
      convertDate,
      convertDateExternal,

      items,
      loading,
      isActive,
      initItems,
      actionsArray,
      getItems,
      isShow,
    }
  },
})
