<template>
  <!--<Layout>-->
  <div class="d-flex flex-column flex-grow-1 h-100">
    <TableDefault @changeheadershow="changeheadershow" :options="config" />
  </div>
  <!--</Layout>-->
</template>

<script>
import { requestSlata } from '@/pages'
import _ from 'lodash'
import { stringField } from '@/utils/fields.js'
import { isOKK, isRG, isROKK } from '@/utils/permissions'
import { betweenValues } from '@/utils/validation.js'

//import Layout from '@/layouts/default/index'
//import Axios from 'axios'

export default {
  name: 'Shop-Request-Magnit-View',
  components: {
    //Layout,
  },
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const config = _.cloneDeep(requestSlata)

    return {
      config,
    }
  },
}
</script>
