import { defineComponent } from 'vue'

const FileReader = defineComponent({
  name: 'FileReader',
  props: {},
  setup() {
    return {}
  },
  data() {
    return {}
  },
  methods: {
    previevImage() {},
  },
})
export default FileReader
