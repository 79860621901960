var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-expansion-panels',{attrs:{"flat":"","accordion":"","multiple":""},model:{value:(_vm.expansion),callback:function ($$v) {_vm.expansion=$$v},expression:"expansion"}},_vm._l((_vm.items),function(item,index){return _c('v-expansion-panel',{key:index,class:[
      _vm.fillActive && 'panelActive',
      _vm.outlined && 'outlined',
      'contractPanel',
      'py-0',
      'px-3',
    ],on:{"change":function($event){return _vm.changePanel({ item, index })}}},[_c('v-expansion-panel-header',{staticClass:"py-0 px-0",staticStyle:{"min-height":"48px"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [(item.loaded === false)?_c('v-progress-circular',{attrs:{"color":"primary","size":22,"indeterminate":""}}):_vm._e()]},proxy:true}],null,true)},[_vm._t("header",null,{"item":item,"index":index})],2),_c('v-expansion-panel-content',[(item.items && item.loaded === false)?_c('div',{staticClass:"plug"}):_vm._e(),_vm._t("content",null,{"item":item,"index":index})],2)],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }