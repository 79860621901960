// import Alert from '@/components/Alert'
// import { ref, computed } from 'vue'
//import useForm from '@/compositions/useForm'
//import { OnlyPassword, required } from '@/utils/validation'
// import { useRoute } from 'vue-router'

export default {
  name: 'Login-Layout',
  components: {},
  setup() {
    return {}
  },
}
