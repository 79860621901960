import { selectField, autocompleteField } from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import { required, hasDate, hasTime, nameLength } from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import router from '@/router'

export default {
  id: uuidv4(),
  path: 'personal-select-account',
  name: 'Переназначить',
  type: 'FormDefault',
  alias: 'object',
  active: false,
  lists: [
    {
      alias: 'transmit_managers_from',
      filter: [],
    },
  ],
  fields: [
    autocompleteField({
      label: 'Кому',
      //subtype: 'multiple',
      name: 'account_id',
      //subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      //readonly: true,
      items: [],
      page: 1,
      search: '',
      url: 'get/pagination_list/transmit_managers_from',
      // object
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
      filter: [
        {
          field: 'without_me',
          source: 'true',
          type: 'boolean',
        },
      ],
      dependence: [
        {
          init: true,
          type: 'custom',
          func: (ctx) => {
            ctx.formData.ids = ctx.tableComp?.proxy?.selectedRowsList.map(
              (x) => x.row.id
            )
          },
        },
      ],
    }),
    selectField({
      label: 'Фильтр для назначения множества',
      name: 'ids',
      subtype: 'multiple',
      placeholder: '',
      readonly: true,
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      isShow: false,
      bootstrapClass: [''],
      //validations: { required },
      //isShow: false,
    }),
  ],
  actions: [
    stringAction({
      text: 'Закрыть',
      type: 'submit',
      color: 'textDefault',
      name: 'closePopup',
      action: 'closePopup',
      skipValidation: true,
    }),
    stringAction({
      text: 'Подтвердить',
      type: 'submit',
      action: 'func',
      color: 'primary',
      func: async (ctx) => {
        const data = await ctx.store.dispatch('form/create', {
          url: 'mass/personal/add',
          body: {
            data: {
              ids: ctx.formData.ids,
              account_to_id: ctx.formData.account_id,
            },
          },
        })
        if (data.code === 1) {
          ctx.tableComp.proxy.hash = data.result
          ctx.tableComp.proxy.localStorage = {
            account_to_id: ctx.formData.account_id,
          }
          router.replace({
            name: 'personal',
          })
          ctx.tableComp.proxy.openTable(data.result, 'add', {
            alias: 'personal',
            successMessage: 'Персонал успешно перенесен',
          })
          return
        }
        if (data.code === 2) {
          ctx.store.commit('notifies/showMessage', {
            color: 'warning',
            content: 'Попробуйте еще раз...',
            timeout: 2000,
          })
        } else if (data.code === 3) {
          ctx.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Что-то пошло не так...',
            timeout: 2000,
          })
        } else if (data.code === 4) {
          ctx.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 2000,
          })
        }
      },
    }),
  ],
}
