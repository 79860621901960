import Vue, {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  nextTick,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import useRequest from '@/compositions/useRequest'
import Autocomplete from '@/components/Autocomplete/default'
import plug from '@/components/Contract/plug'

export default defineComponent({
  id: uuidv4(),
  components: {
    Autocomplete,
    plug,
  },
  path: 'transmit',
  name: 'Передача',
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: async (data) => {
        const response = await store.dispatch('list/get', data)
        return response
      },
    })

    const formData = ref({
      type_action: null,
      account_from_id: null,
      account_to_id: null,
      all_account_transmit: false,
      excluding_ids: [],
      personal_ids: [],
    })

    const search = ref('')
    const personalData = ref({
      page: 0,
      totalPage: 0,
      total: 0,
      items: [],
      loading: false,
    })

    const actions = ref([])
    const actionsLoading = ref(true)

    const accountToRef = ref(null)

    const getActions = async () => {
      actionsLoading.value = true
      const response = await makeRequestList([
        {
          alias: 'personal_transmit_actions',
          filter: [],
        },
      ])
      actions.value = response.data.personal_transmit_actions
      formData.value.type_action = actions.value[0].action
      actionsLoading.value = false
    }

    const changeAccountFrom = () => {
      formData.value.account_to_id = null
      nextTick(async () => {
        await accountToRef.value.getSearch()
        resetSearch()
      })
    }

    let controller
    const searchPersonal = async () => {
      try {
        if (controller) controller.abort()
        controller = new AbortController()
        personalData.value.loading = true
        personalData.value.page++
        const response = await getList(
          'get/pagination_list/transmit_personal',
          {
            countRows: 10,
            currentPage: personalData.value.page,
            searchValue: search.value?.toLowerCase() ?? '',
            id: -1,
            filter: [
              {
                alias: 'to_account_id',
                value: formData.value.account_to_id,
              },
              {
                alias: 'from_account_id',
                value: formData.value.account_from_id,
              },
            ],
            readonly: 0,
          },
          {
            signal: controller.signal,
          }
        )
        personalData.value.totalPage = response.totalPage
        if (response.page === 1 && !search.value) {
          personalData.value.total = response.total
        }
        if (
          response.rows.length &&
          personalData.value.page < personalData.value.totalPage
        ) {
          Vue.set(response.rows.at(-1), 'intersecting', true)
        }
        if (formData.value.all_account_transmit) {
          addChecked(response.rows)
        }
        personalData.value.items = [
          ...personalData.value.items,
          ...response.rows,
        ]

        personalData.value.loading = false
        controller = undefined
      } catch (e) {
        e
      }
    }

    const clearChecked = () => {
      formData.value.personal_ids = []
      formData.value.excluding_ids = []
      formData.value.all_account_transmit = false
    }

    const allChecked = () => {
      formData.value.all_account_transmit = true
      formData.value.excluding_ids = []
      addChecked(personalData.value.items)
    }

    const checkExcluding = (val) => {
      if (formData.value.all_account_transmit) {
        if (formData.value.personal_ids.includes(val)) {
          formData.value.excluding_ids = _.without(
            formData.value.excluding_ids,
            val
          )
        } else {
          formData.value.excluding_ids.push(val)
        }
      }
    }

    const addChecked = (array) => {
      array.forEach((item) => {
        if (
          !formData.value.personal_ids.includes(item.id) &&
          !formData.value.excluding_ids.includes(item.id)
        ) {
          formData.value.personal_ids.push(item.id)
        }
      })
    }

    const resetSearch = () => {
      personalData.value.items = []
      personalData.value.totalPage = 0
      personalData.value.page = 0
    }

    const initSearch = () => {
      resetSearch()
      if (
        formData.value?.account_from_id?.length &&
        formData.value.account_to_id
      ) {
        searchPersonal()
      }
    }

    const requestLoading = ref(false)
    const expandPersonal = async () => {
      requestLoading.value = true
      const response = await store.dispatch(`form/create`, {
        url: `personal/${formData.value.type_action}`,
        body: {
          data: {
            account_from_ids: formData.value.account_from_id,
            personal_ids: formData.value.all_account_transmit
              ? undefined
              : formData.value.personal_ids,
            account_to_id: formData.value.account_to_id,
            all_account_transmit: formData.value.all_account_transmit,
            excluding_ids: formData.value.excluding_ids,
          },
        },
      })
      requestLoading.value = false
      if (response.code === 1) {
        emit('closePopup')
        store.commit('notifies/showMessage', {
          color: 'success',
          content: 'Успешно',
          timeout: 1000,
        })
      } else if (response.code === 2) {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Ошибка сервера',
          timeout: 1000,
        })
      } else if (response.code === 3) {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Невалидные данные',
          timeout: 1000,
        })
      } else if (response.code === 4) {
        store.commit('notifies/showMessage', {
          color: 'error',
          content: 'Нет доступа',
          timeout: 1000,
        })
      }
    }

    const fieldsConfig = {
      account_from_id: {
        label: 'От кого',
        name: 'account_from_id',
        subtype: 'multiple',
        placeholder: '',
        class: [''],
        selectOption: {
          text: 'name',
          value: 'id',
        },
        items: [],
        page: 1,
        search: '',
        url: 'get/pagination_list/transmit_managers_from',
        filter: [],
      },
      account_to_id: {
        label: 'Кому',
        name: 'account_to_id',
        subtype: 'single',
        placeholder: '',
        class: [''],
        selectOption: {
          text: 'name',
          value: 'id',
        },
        items: [],
        page: 1,
        search: '',
        url: 'get/pagination_list/transmit_managers_to',
      },
    }

    onMounted(() => {
      getActions()
    })

    return {
      formData,
      actions,
      actionsLoading,
      search,

      fieldsConfig,
      accountToRef,
      changeAccountFrom,
      searchPersonal,
      resetSearch,
      personalData,
      initSearch,
      clearChecked,
      allChecked,
      checkExcluding,
      requestLoading,
      expandPersonal,
    }
  },
})
