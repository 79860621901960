var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_vm._t("activator",null,{"on":() => (_vm.isShow = true)}),(_vm.isShow)?_c('Popup',{attrs:{"options":{
      width: '500px',
      portal: 'table-detail',
    }}},[_c('v-card',{staticClass:"pa-6"},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('span',{staticClass:"font-weight-700 text--text font-size-24"},[_vm._v(_vm._s(_vm.additional ? 'Дополнительное соглашение' : 'Версия соглашения'))]),_c('span',{staticClass:"font-weight-500 primary--text"},[_vm._v("v "+_vm._s(_vm.version))])]),_c('v-divider',{staticClass:"my-3"}),_c('div',[_c('div',{staticClass:"text--text mb-2"},[_vm._v("Название соглашения")]),_c('v-text-field',{staticClass:"textField",attrs:{"clearable":"","dense":"","error-messages":_vm.formErrors.name,"outlined":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),(_vm.config.date)?_c('div',[_c('div',{staticClass:"text--text mb-2"},[_vm._v("Срок действия")]),_c('div',{staticClass:"d-flex"},[_c('Datepicker',{staticClass:"datePicker",attrs:{"options":{
              dense: true,
              hideDetails: false,
              'hide-options': true,
              outlined: true,
            },"error-messages":_vm.formErrors.date_from},model:{value:(_vm.formData.date_from),callback:function ($$v) {_vm.$set(_vm.formData, "date_from", $$v)},expression:"formData.date_from"}}),_c('v-icon',{staticClass:"mx-4 pb-4 cursor-default",attrs:{"size":"16"}},[_vm._v("mdi-arrow-right")]),_c('Datepicker',{staticClass:"datePicker",attrs:{"options":{
              dense: true,
              hideDetails: false,
              'hide-options': true,
              outlined: true,
            },"error-messages":_vm.formErrors.date_to},model:{value:(_vm.formData.date_to),callback:function ($$v) {_vm.$set(_vm.formData, "date_to", $$v)},expression:"formData.date_to"}})],1)]):_vm._e(),(_vm.config.prolongation)?_c('div',{staticClass:"mb-3"},[_c('v-switch',{staticClass:"dialogSwitch",attrs:{"dense":"","disabled":_vm.formData.with_prolongation,"inset":"","hide-details":"","color":"primary","label":"Загрузить тарифы"},model:{value:(_vm.formData.prolongation_tarif),callback:function ($$v) {_vm.$set(_vm.formData, "prolongation_tarif", $$v)},expression:"formData.prolongation_tarif"}})],1):_vm._e(),_c('div',[_c('DropZone',{attrs:{"options":_vm.fields.file.options,"formData":_vm.formData,"field":_vm.fields.file,"error-messages":_vm.formErrors.file},model:{value:(_vm.formData.file),callback:function ($$v) {_vm.$set(_vm.formData, "file", $$v)},expression:"formData.file"}})],1),_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"0","loading":_vm.loading},on:{"click":function($event){_vm.isShow = false}}},[_vm._v("Закрыть")]),_c('v-btn',{attrs:{"elevation":"0","loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.clickHandler({
              action: {
                module: 'form/create',
                url: _vm.additional
                  ? 'add/contract/additional'
                  : 'add/contract/version',
                action: 'saveFormStore',
                notClose: true,
              },
            })}}},[_vm._v("Сохранить")])],1)],1)],1):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }