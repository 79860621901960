<template>
  <div class="d-flex flex-column flex-grow-1 h-100"><Contract /></div>
</template>

<script>
import Contract from '@/components/Contract/default/index.vue'
export default {
  name: 'Contract-View',

  components: { Contract },
  setup() {
    return {}
  },
}
</script>
