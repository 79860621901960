import useView from '@/compositions/useView.js'
import _ from 'lodash'
const {
  initTableConfig,
  createHeadItem,
  convertConfigPanel,
  addCloseButton,
  configRouteConvert,
} = useView({})
import { stringAction } from '@/utils/actions'

const initPaymentZayavka = (paymentConfigOrig, zayavkaConfigOrig) => {
  const paymentConfig = initTableConfig({
    path: 'edit',
    name: 'Начисления и выплаты',
    config: _.cloneDeep(paymentConfigOrig),
    isShow: {
      value: true,
      condition: [
        {
          permissions: [16, 19, 7],
          type: false,
        },
      ],
    },
  })
  const zayavkaConfig = initTableConfig({
    path: 'edit',
    name: 'Расход',
    config: _.cloneDeep(zayavkaConfigOrig),
    isShow: {
      value: true,
      condition: [
        {
          permissions: [7],
          type: false,
        },
      ],
    },
  })

  paymentConfig.config.options = {
    ...paymentConfig.config.options,
    urlDetail: 'personal_id',
    alias: 'p.personal_id',
  }

  zayavkaConfig.config.options = {
    ...zayavkaConfig.config.options,
    urlDetail: 'personal_id',
    alias: 'z.personal_id',
  }

  createHeadItem({
    config: zayavkaConfig.config.head,
    item: {
      title: 'Создано',
      alias: 'z.date_create',
      value: 'date_create',
    },
  })

  const LIST_HEAD_PAYMENTS = [
    'status_name',
    'account_name',
    'date_add',
    'bank_fio',
    'total',
  ]
  const LIST_HEAD_ZAYAVKA = [
    'status_name',
    'category_name',
    'schet',
    'date_create',
    'total',
    'price',
  ]
  const LIST_PANEL = ['Обновить']

  convertConfigPanel({
    config: paymentConfig.config,
    listHead: LIST_HEAD_PAYMENTS,
    listPanel: LIST_PANEL,
  })
  convertConfigPanel({
    config: zayavkaConfig.config,
    listHead: LIST_HEAD_ZAYAVKA,
    listPanel: LIST_PANEL,
  })

  addCloseButton(paymentConfig.config)
  addCloseButton(zayavkaConfig.config)

  return { paymentConfig, zayavkaConfig }
}

/**
`config` - original config;
`options`: {
  alias: '',
  action: '',
  popupSubtitle: '',
}

*/
const initMassActionsPopup = (config, options) => {
  const paymentConfig = initTableConfig({
    path: `${options.alias}-many`,
    name: 'Подтвеждние действий',
    config: _.cloneDeep(config),
  })
  const index = config.detail.tabs.push(_.cloneDeep(paymentConfig)) - 1
  delete config.detail.tabs[index].config.panel.buttons
  delete config.detail.tabs[index].config.detail
  delete config.detail.tabs[index].config.filters
  delete config.detail.tabs[index].config.bottomPanel
  delete config.detail.tabs[index].config.options.contextMenu
  config.detail.tabs[index].config.options.selecting = false
  config.detail.tabs[index].descrition = options.popupSubtitle
  config.detail.tabs[index].config.actions = [
    stringAction({
      text: 'Отменить',
      status_id: 3,
      type: 'submit',
      module: 'form/putForm',
      name: 'saveFormId',
      url: 'update/payment',
      action: 'saveFormId',
      color: 'error',
      func: async (context, storage, hash) => {
        const data = await context.store.dispatch('form/delForm', {
          url: `mass/cancel/${options.alias}/${options.action}`,
          body: { data: { hash: hash, mass_action: storage?.action } },
        })
        if (data.code === 1) {
          return true
        }
        if (data.code === 2) {
          context.store.commit('notifies/showMessage', {
            color: 'warning',
            content: 'Попробуйте еще раз...',
            timeout: 2000,
          })
        } else if (data.code === 3) {
          context.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Что-то пошло не так...',
            timeout: 2000,
          })
        } else if (data.code === 4) {
          context.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 2000,
          })
        }
      },
    }),
    stringAction({
      text: 'Применить',
      status_id: 2,
      type: 'submit',
      module: 'form/putForm',
      name: 'saveFormId',
      url: 'update/payment',
      action: 'saveFormId',
      color: 'accept',
      func: async (context, storage, hash) => {
        const { action, ...properties } = storage
        const data = await context.store.dispatch('form/putForm', {
          url: `mass/accept/${options.alias}/${options.action}`,
          body: {
            data: {
              hash: hash,
              ...properties,
            },
          },
        })
        if (data.code === 1) {
          context.store.commit('notifies/showMessage', {
            color: 'success',
            content: options.successMessage ?? 'Действие выполнено успешно',
            timeout: 2000,
          })
          return true
        }
        if (data.code === 2) {
          context.store.commit('notifies/showMessage', {
            color: 'warning',
            content: 'Попробуйте еще раз...',
            timeout: 2000,
          })
        } else if (data.code === 3) {
          context.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Что-то пошло не так...',
            timeout: 2000,
          })
        } else if (data.code === 4) {
          context.store.commit('notifies/showMessage', {
            color: 'error',
            content: 'Нет доступа',
            timeout: 2000,
          })
        }
      },
    }),
  ]
  return config
}

export { initPaymentZayavka, initMassActionsPopup }
