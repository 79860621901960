var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column flex-grow-1 h-100 view-table"},[_c('v-tabs',{staticClass:"p-5",staticStyle:{"flex":"unset"},attrs:{"background-color":"transparent","color":"basil","mobile-breakpoint":"0"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.availableTabs),function(item){return _c('v-tab',{key:item.options.title},[_vm._v(" "+_vm._s(item.options.title)+" ")])}),1),_c('v-tabs-items',{attrs:{"touchless":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.availableTabs),function(item){return _c('v-tab-item',{key:item.options.title},[(item.options.title === 'Поиск')?[_c('div',{staticStyle:{"margin":"10px 10px 0 10px"}},[_c('v-text-field',{attrs:{"label":"Поиск","outlined":"","hide-details":"","dense":"","clearable":""},on:{"input":_vm.rebuildSearchTab},model:{value:(_vm.searchTab),callback:function ($$v) {_vm.searchTab=$$v},expression:"searchTab"}})],1)]:_vm._e(),(!_vm.loading || item.options.title !== 'Поиск')?_c(item.type,{ref:"tabs",refInFor:true,tag:"component",attrs:{"options":item,"filter":item.options.title === 'Поиск'
            ? [
                {
                  value: _vm.searchTab,
                  alias: 'search',
                  type: 'string',
                  subtype: 'simple',
                },
              ]
            : undefined},on:{"changeheadershow":_vm.changeheadershow}}):_vm._e()],2)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }