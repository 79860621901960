<template>
  <div class="d-flex flex-column flex-grow-1 h-100">
    <TableDefault @changeheadershow="changeheadershow" :options="config" />
  </div>
</template>

<script>
import { reportDebt } from '@/pages'
import _ from 'lodash'

export default {
  name: 'ReportDebt-View',

  components: {},
  setup() {
    const config = _.cloneDeep(reportDebt)
    return {
      config,
    }
  },
}
</script>
