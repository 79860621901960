var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding-top":"20px"}},[_c('PersTitle',{attrs:{"data":{
        surname: _vm.data.entity.surname,
        name_n: _vm.data.entity.name_n,
        patronymic: _vm.data.entity.patronymic,
        dataRojd: _vm.data.entity.data_rojd.split('-').reverse().join('.'),
      }}}),(_vm.textInfo)?_c('TextInfo',{staticClass:"mb-3",attrs:{"infoObj":_vm.textInfo}}):_vm._e(),_c('span',{staticClass:"font-weight-bold mb2"},[(_vm.isLoadedImage || _vm.data.data.docs.length)?_c('v-icon',{attrs:{"small":""}},[_vm._v("$IconGalka")]):_vm._e(),_vm._v("Прикрепите реквизиты: ")],1),_c('Dropzone',{staticClass:"mt-2",attrs:{"options":{
        withoutSave: false,
        folder: 'tmp',
        removeble: false,
      }},on:{"addFiles":_vm.addFiles}}),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{attrs:{"small":"","color":"info","disabled":!_vm.isLoadedImage,"loading":_vm.loading},on:{"click":_vm.sendTaskFinish}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }