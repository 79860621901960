import Vue, {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  watch,
} from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import exPanel from '@/components/Contract/exPanel'
import Doljnost from '@/components/Contract/doljnost'
import Popup from '@/components/Popup/index.vue'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Tarif from '@/components/Contract/tariff/individual'
// import versionChange from '@/components/Contract/types/x5/pact/dialog'
import plug from '@/components/Contract/plug'
import Version from '@/components/Contract/dialog/version'
import addEntity from '@/components/Contract/dialog/addEntity'
// import historyChange from '@/pages/object/config/custom-object-tarif/historyChange'

export default defineComponent({
  props: {},
  components: {
    exPanel,
    Popup,
    Doljnost,
    Tarif,
    Coefficient,
    Version,
    addEntity,
    // versionChange,
    // historyChange,
    plug,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const createContract = async ({ formData }) => {
      return await store.dispatch(`form/create`, {
        url: 'create/contract',
        body: {
          data: {
            name: formData.name,
            type_object_id: 12,
          },
        },
      })
    }

    const chigikBtn = [
      { name: 'Договоры', value: 0 },
      { name: 'Регионы', value: 1 },
    ]

    let controller
    const getChigikType = async () => {
      if (controller) controller.abort()
      controller = new AbortController()
      chigik.value.loading = true
      let response
      try {
        if (chigik.value.docType === 0) {
          response = await store.dispatch('form/getParams', {
            url: 'get/contract/chigik',
            params: {
              signal: controller.signal,
            },
          })
          response.data.forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          chigik.value.items = response.data
        } else if (chigik.value.docType === 1) {
          const list = 'contract_regions'
          response = await store.dispatch('form/update', {
            url: 'get/lists',
            body: [{ alias: list, filter: [] }],
            params: {
              signal: controller.signal,
            },
          })
          response.data[list].forEach((item) => {
            Vue.set(item, 'loaded', null)
            Vue.set(item, 'items', null)
          })
          chigik.value.items = response.data[list]
        }
      } catch (e) {
        return e
      }
      controller = undefined
      chigik.value.loading = false
    }

    const chigik = ref({
      items: [],
      loading: false,
      docType: 0,
      refresh: getChigikType,
    })

    const getCities = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'contract_city',
          filter: [
            {
              alias: 'region_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObjectsByCity = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'chigik_object_by_city',
          filter: [
            {
              alias: 'city_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getObject = async (item) => {
      return await store.dispatch('list/get', [
        {
          alias: 'active_doljnost_by_object',
          filter: [
            {
              alias: 'object_id',
              value: [item.id],
            },
          ],
        },
      ])
    }

    const getVersions = async (item) => {
      return await store.dispatch(
        'form/get',
        `get/contract/versions/${item.id}`
      )
    }

    const refreshPanel = (item) => {
      item.refresh()
    }

    const convertDate = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    const download = (url) => {
      Vue.downloadFile(url)
    }

    watch(
      () => chigik.value.docType,
      () => {
        getChigikType()
      },
      { immediate: true }
    )

    return {
      chigikBtn,
      chigik,

      refreshPanel,
      getChigikType,
      getCities,
      getObjectsByCity,
      getObject,
      getVersions,

      convertDate,
      download,

      addEntity,
      createContract,
    }
  },
})
