import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
export default {
  name: 'Table-Actions-Panel',
  props: {
    selectedItems: Array,
    options: Object,
  },
  setup(props, ctx) {
    const switchStatus = ref(false)
    const container1 = ref(null)

    const visibleButtons = ref([...(props?.options?.buttons ?? [])])
    const menuButtons = ref([])

    const buttonsLength = ref([])

    let hiddenButtonsCount = 0

    watch(switchStatus, (x) => {
      ctx.emit('onToggleSwich', x)
    })

    const replaceButtons = (toMenu) => {
      if (toMenu) {
        let button = visibleButtons.value.pop()
        if (button) menuButtons.value.push(button)
      } else {
        let button = menuButtons.value.pop()
        if (button) visibleButtons.value.push(button)
      }
    }

    //TODO:
    const calcButtonsPosition = () => {
      while (
        container1.value.offsetWidth >
          currBlockLength(hiddenButtonsCount - 1) ||
        container1.value.offsetWidth < currBlockLength(hiddenButtonsCount)
      ) {
        if (
          currBlockLength(hiddenButtonsCount) > container1.value.offsetWidth
        ) {
          replaceButtons(true)
          if (hiddenButtonsCount === 0) {
            buttonsLength.value = [118, ...buttonsLength.value]
          }
          hiddenButtonsCount++
        }
        if (
          currBlockLength(hiddenButtonsCount - 1) < container1.value.offsetWidth
        ) {
          replaceButtons(false)
          if (hiddenButtonsCount === 1) {
            buttonsLength.value.shift()
          }
          hiddenButtonsCount--
        }
      }
    }

    const currBlockLength = (count = 0) => {
      if (count === -1) return 9999
      if (count >= buttonsLength.value.length - 1) return 0
      let sum = 0
      for (let i = buttonsLength.value.length - count - 1; i >= 0; i--) {
        sum += buttonsLength.value[i]
      }
      return sum
    }

    const resetSelected = () => {
      switchStatus.value = false
      ctx.emit('onReset')
    }

    const panelAction = (button) => {
      let data = []
      if (!props.selectedItems?.length) {
        return
      }
      ctx.emit('onAction', button)
    }
    function resizeHandler() {
      calcButtonsPosition()
    }

    onMounted(() => {
      hiddenButtonsCount = 0
      visibleButtons.value = [...(props?.options?.buttons ?? [])]
      menuButtons.value = []
      window.addEventListener('resize', resizeHandler)
      for (let i = 0; i < container1.value.children.length; i++) {
        buttonsLength.value.push(container1.value.children[i].clientWidth + 12)
      }
      resizeHandler()
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      switchStatus,
      menuButtons,
      visibleButtons,
      buttonsLength,
      resetSelected,
      panelAction,
      container1,
    }
  },
}
