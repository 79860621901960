import { defineComponent } from 'vue'

const formError = defineComponent({
  name: 'FormError',
  props: {},
  setup() {
    return {}
  },
})
export default formError
