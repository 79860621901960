import _ from 'lodash'

export const config = {
  title: 'Задолженность',
  selector: '#mainTable',
  type: 'TableDefault',
  options: {
    selecting: true,
    search: {
      //   function: searchInputing,
    },
    headerFixed: true,
    url: 'get/pagination/debt',
    title: 'Задолженность',
  },
  panel: {
    date: true,
    search: true,
  },
  head: [
    {
      title: 'Сотрудник',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '400',
      alias: "TRIM(CONCAT(p.surname, ' ', p.name_n, ' ', p.patronymic))",
      value: 'personal_name',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Переплата',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '400',
      alias: 'IF(h.remainder IS NULL, 0, h.remainder)',
      value: 'hold_sum',
      search: {
        field: '',
        isShow: true,
      },
    },
    {
      title: 'Задолженность',
      type: 'default',
      align: 'center',
      fixed: {
        value: false,
        position: 'left',
      },
      sorts: [
        {
          type: 'text',
          default: '',
          value: '',
          isShow: false,
        },
      ],
      isShow: true,
      width: '400',
      alias: 'IF(d.remainder IS NULL, 0, d.remainder)',
      value: 'debit_sum',
      search: {
        field: '',
        isShow: true,
      },
    },
  ],
  data: {
    rows: [],
    totalRows: null,
    pageLength: 20,
    currentPage: 1,
    totalPages: null,
    footer: null,
  },
  detail: {},
}

export default config
