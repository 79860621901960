var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"total"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"5"}},[_c('p',{staticClass:"weight"},[_vm._v("Подытог:")])]),_c('v-col',{attrs:{"cols":"12","sm":"7"}},_vm._l((_vm.fields),function(field,fieldKey){return _c('v-row',{staticClass:"justify-space-between"},[_c('p',{class:fieldKey === 'total_by_services' || fieldKey === 'total'
              ? 'weight'
              : 'default'},[_vm._v(" "+_vm._s(field)+" ")]),(!_vm.loading)?_c('p',{class:['total_coefficient', 'total_by_services'].includes(fieldKey) &&
            _vm.info[fieldKey] !== 0
              ? 'green--text'
              : fieldKey !== 'total' && _vm.info[fieldKey] !== 0
              ? 'red--text'
              : ''},[(
              ['total_coefficient', 'total_by_services'].includes(fieldKey) &&
              _vm.info[fieldKey] !== 0 &&
              fieldKey !== 'total'
            )?_c('span',[_vm._v("+")]):(
              ['total_hold', 'total_debit', 'total_everyday'].includes(
                fieldKey
              ) &&
              _vm.info[fieldKey] !== 0 &&
              fieldKey !== 'total'
            )?_c('span',[_vm._v("-")]):_vm._e(),_c('span',{class:fieldKey === 'total' && _vm.info[fieldKey] > 0
                ? 'green--text'
                : fieldKey === 'total' && _vm.info[fieldKey] < 0
                ? 'red--text'
                : ''},[_vm._v(_vm._s(_vm.info[fieldKey]))])]):_vm._l((1),function(loading){return _c('div',{key:loading,staticClass:"form-row-loading"},[_c('div',{staticClass:"form-row-loading-wrap gradient"})])})],2)}),1)],1),_c('v-row',{staticClass:"justify-end mt-4 topay"},[_c('p',{staticClass:"d-flex"},[_vm._v(" Начислено: "),(!_vm.loading)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.info.total_payment)+" р")]):_vm._l((1),function(loading){return _c('div',{key:loading,staticClass:"ml-2 form-row-loading"},[_c('div',{staticClass:"form-row-loading-wrap gradient"})])})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }