var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.x5.loading)?_c('v-row',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1):_vm._e(),(_vm.x5.items.length)?_c('v-row',{staticClass:"btn-container mb-3"},_vm._l((_vm.x5.items),function(territory,territoryIndex){return _c('v-btn',{key:territory.id,class:['btn', 'text-none'],attrs:{"min-height":"48px","elevation":"0","color":'primary',"outlined":!_vm.x5.activeItems.find((x) => x.id === territory.id)},on:{"click":[function($event){if(!$event.shiftKey)return null;return _vm.changeSelection({
          index: territoryIndex,
          btn: 'shift',
          item: territory,
          items: _vm.x5.items,
          activeItems: _vm.x5.activeItems,
          entity: _vm.x5,
          request: _vm.getActiveTerritories,
        })},function($event){if(!$event.ctrlKey)return null;return _vm.changeSelection({
          index: territoryIndex,
          btn: 'ctrl',
          item: territory,
          items: _vm.x5.items,
          activeItems: _vm.x5.activeItems,
          entity: _vm.x5,
          request: _vm.getActiveTerritories,
        })},function($event){if(!$event.metaKey)return null;return _vm.changeSelection({
          index: territoryIndex,
          btn: 'ctrl',
          item: territory,
          items: _vm.x5.items,
          activeItems: _vm.x5.activeItems,
          entity: _vm.x5,
          request: _vm.getActiveTerritories,
        })},function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;return _vm.changeSelection({
          index: territoryIndex,
          btn: 'default',
          item: territory,
          items: _vm.x5.items,
          activeItems: _vm.x5.activeItems,
          entity: _vm.x5,
          request: _vm.getActiveTerritories,
        })}]}},[_vm._v(" "+_vm._s(territory.name)+" ")])}),1):_vm._e(),_c('v-row',[_c('addEntity',{attrs:{"request":_vm.createX5Territory},on:{"refresh":_vm.x5.refresh},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"px-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","large":"","min-width":"30"},on:{"click":on}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить территорию")],1)]}}])})],1),_c('v-row',[_c('v-card',{staticClass:"px-1 py-1",attrs:{"elevation":"0"}},_vm._l((_vm.switchBtn),function(btn,btnIndex){return _c('v-btn',{key:btnIndex,class:[_vm.x5.docType === btnIndex && 'btn--active', 'text-none'],attrs:{"min-height":"35px","elevation":"0","color":_vm.x5.docType === btnIndex ? 'primary' : 'text',"text":_vm.x5.docType !== btnIndex},on:{"click":function($event){return _vm.changeDocType(btnIndex)}}},[_vm._v(" "+_vm._s(btn.name)+" ")])}),1)],1),_vm._l((_vm.x5.activeItems),function(territory){return _c('div',{key:territory.id},[_c('v-row',{staticClass:"my-3 justify-space-between align-center"},[_c('div',{staticClass:"text--text font-weight-700 text-cut",staticStyle:{"flex":"1"}},[_c('span',{attrs:{"title":territory.name}},[_vm._v(" "+_vm._s(territory.name)+" ")])]),_c('div',{staticClass:"d-flex ml-2"},[_c('Tarif',{staticClass:"mr-3",attrs:{"tarif":territory.have_individual,"requestData":{ entity: 'territory', id: territory.id }}}),_c('Coefficient',{staticClass:"mr-3",attrs:{"requestData":{ entity: 'territory', id: territory.id },"coef":territory.coefficient}})],1)]),_c('v-divider',{staticClass:"mb-3"}),_c('addEntity',{attrs:{"options":_vm.x5.docType === 0
          ? { contractType: _vm.list?.agreement_dictionary }
          : { territory: _vm.list?.x5_territories },"entity":_vm.x5.docType === 0 ? { territory_id: territory.id } : undefined,"request":_vm.x5.docType === 0 ? _vm.createContract : _vm.createX5Zone},on:{"refresh":() => _vm.getTerritoryItem({ item: territory, refresh: true })},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',{staticClass:"px-3 mb-3 text-none",staticStyle:{"border":"1px dashed"},attrs:{"elevation":"0","color":"primary","outlined":"","large":"","min-width":"30"},on:{"click":on}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","size":"20"}},[_vm._v("mdi-plus")]),_vm._v("Добавить "+_vm._s(_vm.x5.docType === 0 ? 'договор' : 'зону'))],1)]}}],null,true)}),(territory.loading)?[_c('v-row',{staticClass:"justify-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1)]:(!territory.items?.length)?[_c('plug',{attrs:{"text":`${_vm.x5.docType === 0 ? 'Договора' : 'Зоны'} не найдены`}})]:(_vm.x5.docType === 0)?[_c('pact',{attrs:{"items":territory.items,"territory":territory},on:{"openParser":(e) => _vm.$emit('openParser', e)}})]:(_vm.x5.docType === 1)?[_c('zone',{attrs:{"items":territory.items},on:{"refresh":function($event){return _vm.getTerritoryItem({ item: territory, refresh: true })}}})]:_vm._e()],2)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }