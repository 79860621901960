var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.tab)?_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-row"},[_c('v-form',[_vm._l((_vm.tab.formData.date_target),function(row){return _c('Row',{key:row.date +
          '_' +
          _vm.tab.formData.personal_id +
          '_' +
          _vm.tab.formData.object_id +
          '_' +
          _vm.tab.formData.direction_id +
          '_' +
          _vm.tab.formData.doljnost_id +
          '_' +
          _vm.tab.formData.subtypr,ref:"rows",refInFor:true,attrs:{"tab":_vm.tab,"tabs":_vm.tabs,"target":row,"row":row,"formData":_vm.tab.formData,"formErrors":_vm.formErrors,"loading":_vm.loading,"activeTab":_vm.activeTab}})}),(_vm.tab?.actions?.length)?_c('v-row',{staticClass:"justify-end"},_vm._l((_vm.tab.actions),function(action){return _c('v-btn',{key:action.id,staticClass:"ml-2",attrs:{"type":"submit","color":action.color,"loading":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.clickHandler({ action })}}},[_vm._v(" "+_vm._s(action.text)+" ")])}),1):_vm._e()],2)],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }