<template>
  <!--<Layout>-->
  <div class="d-flex flex-column flex-grow-1 h-100 view-table">
    <!-- <TableDefault @changeheadershow="changeheadershow" :options="reportTaxi" /> -->
    <v-tabs
      style="flex: unset"
      v-model="activeTab"
      background-color="transparent"
      color="basil"
      class="p-5"
      mobile-breakpoint="0"
    >
      <v-tab v-for="item in reportTaxi.tabs" :key="item.options.title">
        {{ item.options.title }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="activeTab">
      <v-tab-item v-for="item in reportTaxi.tabs" :key="item.options.title">
        <component
          ref="tabs"
          :is="item.type"
          @changeheadershow="changeheadershow"
          :options="item"
        />
      </v-tab-item>
    </v-tabs-items>
  </div>
  <!--</Layout>-->
</template>

<script type="module">
import { ref, computed, onMounted } from 'vue'
import { reportTaxi } from '@/pages'
import TableFixed from '@/components/Table/fixed/index.vue'
import useView from '@/compositions/useView.js'
//import Layout from '@/layouts/default/index'
//import Axios from 'axios'
export default {
  name: 'ReportTaxi-View',
  components: {
    //Layout,
    TableFixed,
  },
  methods: {
    changeheadershow(options) {
      const { headerEl, value } = options
      headerEl.isShow = value
    },
  },
  setup() {
    const activeTab = ref(0)
    const tabs = ref([])
    useView({
      tabs,
      activeTab,
    })
    return {
      reportTaxi,
      activeTab,
      tabs,
    }
  },
}
</script>
