import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  dropZoneField,
  checkboxField,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import _ from 'lodash'
import {
  required,
  hasDate,
  hasTime,
  nameLength,
  minLength,
  numeric,
} from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'
import formServiceAddEdit from './form-service-add-edit'

export default {
  path: 'edit',
  id: uuidv4(),
  name: 'Услуги',
  type: 'TableDefault',
  active: false,
  route: 'paw',
  config: {
    selector: '#mainTable',
    options: {
      selecting: true,
      search: {
        function: null,
      },
      headerFixed: true,
      //url: 'https://dummyjson.com/users',
      url: 'get/pagination/wop_x5_service',
      // urlDetail: 'service_id',
      alias: 'doljnost_id',
      title: 'This is an about page1',
      contextMenu: {
        actions: [
          {
            icon: '$IconDelete',
            label: 'Удалить',
            isShow: {
              condition: [
                {
                  funcCondition: (context) => {
                    const object_type = context.store.state.user.object_types
                    const permission = context.store.state.user.permission_id
                    const res =
                      ([3, 4].includes(permission) &&
                        object_type.includes(1)) ||
                      [8, 17].includes(permission)
                    return res
                  },
                  type: true,
                },
              ],
            },
            action: {
              type: 'confirm',
              dialog: {
                text: 'Вы подтверждаете удаление услуги?',
                function: async (context) => {
                  await context.store.dispatch('form/delForm', {
                    url: `delete/doljnost`,
                    body: { data: { id: context.data.row.id } },
                  })
                },
              },
            },
          },
        ],
      },
    },
    panel: {
      buttons: [
        {
          label: 'Обновить',
          class: ['v-table-button--custom'],
          url: '$IconEdit',
          backgroundColor: '#ffffff',
        },
        {
          label: 'Добавить',
          class: ['v-table-button--custom'],
          type: 'pushUrl',
          url: '/add_service',
          backgroundColor: '#fff',
          isShow: {
            condition: [
              {
                funcCondition: (context) => {
                  const object_type = context.store.state.user.object_types
                  const permission = context.store.state.user.permission_id
                  const res =
                    ([2, 3, 4].includes(permission) &&
                      object_type.includes(1)) ||
                    [8, 17].includes(permission)
                  return res
                },
                type: true,
              },
            ],
          },
        },
      ],
    },
    head: [
      {
        title: 'ID',
        type: 'default',
        align: 'center',
        fixed: {
          value: false,
          position: 'left',
        },
        sorts: [
          {
            type: 'string',
            default: '',
            value: '',
            isShow: false,
          },
        ],
        alias: 'wxs.id',
        isShow: true,
        width: '40',
        value: 'id',
        search: {
          field: '',
          isShow: true,
          alias: 'wxs.id',
        },
      },
      {
        title: 'Название',
        type: 'default',
        align: 'center',
        fixed: {
          value: false,
          position: undefined,
        },
        sorts: [
          {
            type: 'string',
            default: '',
            value: '',
            isShow: false,
          },
        ],
        isShow: true,
        width: '150',
        value: 'name',
        alias: 'wxs.category_id',
        search: {
          field: '',
          isShow: true,
          alias: 'wxs.name',
        },
      },
      {
        title: 'Коэффициент',
        type: 'default',
        align: 'center',
        fixed: {
          value: false,
          position: 'left',
        },
        sorts: [
          {
            type: 'string',
            default: '',
            value: '',
            isShow: false,
          },
        ],
        isShow: true,
        width: '90',
        alias: 'wxs.coefficient_name',
        value: 'coefficient_name',
        search: {
          field: '',
          isShow: true,
          alias: 'cd.name',
        },
      },
    ],
    data: {
      rows: [],
      totalRows: null,
      pageLength: 20,
      currentPage: 1,
      totalPages: null,
      footer: null,
    },
    detail: {
      type: 'popup', // String 'popup' or 'page'
      getOnClose: true,
      classes: [''], // List class
      width: '500px',
      method: 'get',
      alias: 'wop_x5_service',
      url: '/get/form/',
      requestId: 'wop_x5_service',
      name: 'Услуга',
      bootstrapClass: [''], // List class from bootstrap ( col-6, pa-2... )
      activeTab: null,
      tabs: [_.cloneDeep(formServiceAddEdit), _.cloneDeep(formServiceAddEdit)],
    },
    actions: [
      stringAction({
        text: 'Закрыть',
        type: 'submit',
        color: 'textDefault',
        name: 'closePopup',
        action: 'closePopup',
        to: 'personal',
        skipValidation: true,
      }),
    ],
  },
}
