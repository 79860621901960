import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import Coefficient from '@/components/Contract/tariff/coefficient'
import Tarif from '@/components/Contract/tariff/individual'
import ChangeMenu from '@/components/Contract/tariff/changeMenu'
import plug from '@/components/Contract/plug'
import historyChange from '@/components/Contract/tariff/historyChange'
// import history from '@/pages/object/config/custom-object-tarif/history'

export default defineComponent({
  props: {
    tarif: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
    object: {
      type: Object,
      default: () => {},
    },
    styleType: {
      type: Number,
      default: 1,
    },
    parentEntity: {
      type: String,
      default: 'object',
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Coefficient,
    Tarif,
    ChangeMenu,
    plug,
    historyChange,
    // history,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const loading = ref(true)
    const doljnost = ref(null)
    const tariff = ref([])

    const allowedPermission = computed(() =>
      [4, 8, 17].includes(store.state.user.permission_id)
    )

    const RATE_STRUCTURE = [
      { title: 'База', key: 'base_rate' },
      { title: 'I', key: '1_rate' },
      { title: 'II', key: '2_rate' },
      { title: 'III', key: '3_rate' },
      { title: 'IV', key: '4_rate' },
    ]
    const DOLJNOST_STRUCTURE = [
      {
        title: 'Внешная ставка',
      },
      {
        title: 'Внутренняя ставка',
        subtitle: 'Аванс | Зарплата',
        individual: true,
        coef: true,
      },
      {
        subtitle: 'Ежедневная',
        individual: true,
        coef: true,
      },
    ]

    const getData = async () => {
      loading.value = true
      try {
        const response = await store.dispatch(
          'form/get',
          `get/tariff/${props.parentEntity}/${props.object.id}/${props.data.id}`
        )
        doljnost.value = response.result
        tariff.value = DOLJNOST_STRUCTURE.reduce((acc, item, index) => {
          if (index) {
            let i
            if (response.result?.active_coefficient?.[index]) {
              i = response.result?.active_coefficient?.[index]
              if (response.result.individual_price?.[index]) {
                i = { ...i, ...response.result.individual_price?.[index] }
              }
            }
            acc.push(i)
          } else {
            acc.push(response.result.external_tariff_data)
          }
          return acc
        }, [])
      } finally {
        loading.value = false
        if (_.isEmpty(doljnost.value)) doljnost.value = null
      }
    }

    const convertData = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    onMounted(() => {
      getData()
    })

    return {
      DOLJNOST_STRUCTURE,
      RATE_STRUCTURE,
      loading,
      doljnost,
      tariff,
      convertData,
      getData,
      allowedPermission,
    }
  },
})
