import Vue, { onMounted, nextTick, computed, ref, watch } from 'vue'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import parserTarif from '@/pages/object/config/form-object-parser'
import Coefficient from './../tariff/coefficient'
import magnit from './../types/magnit'
import chigik from './../types/chigik'
import x5 from './../types/x5'
import exPanel from './../exPanel'
import Popup from '@/components/Popup/index.vue'

export default {
  name: 'Contract',
  props: {},
  components: {
    Coefficient,
    exPanel,
    Popup,
    magnit,
    chigik,
    x5,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }
    const loading = ref(true)
    const types = ref([])
    const parser = ref(false)

    const getTypes = async () => {
      const response = await store.dispatch(
        'form/get',
        'get/object/contract/types'
      )
      types.value = response.data
      loading.value = false
    }

    const parserClone = ref()
    const openParser = ({ territory, contract, version, type }) => {
      parserClone.value = _.cloneDeep(parserTarif)
      const fields = parserClone.value.fields

      fields.find((x) => x.name === 'contract_object_types').value = type
      fields
        .find((x) => x.name === 'type_contract_id')
        .filter.push({
          alias: 'version',
          sendEmpty: true,
          value: version,
        })
      if (type === 1) {
        fields.find((x) => x.name === 'territory_id').value = territory.id
        fields.find((x) => x.name === 'agreement').value = contract.id
      } else if (type === 2) {
        fields.find((x) => x.name === 'magnit_agreement').value = contract.id
      } else if (type === 3) {
        fields.find((x) => x.name === 'chigik_agreement').value = contract.id
      }

      fields.forEach((item) => {
        item.readonly = true
      })

      parser.value = true
    }
    const mainCoef = ref({})
    const getMainCoef = async () => {
      const response = await store.dispatch('form/get', 'get/coefficient/main')
      mainCoef.value = response.data
    }

    const refreshType = () => {
      getTypes()
      getMainCoef()
    }

    onMounted(async () => {
      refreshType()
    })

    return {
      loading,
      types,
      mainCoef,
      parser,
      parserClone,

      openParser,

      refreshType,
    }
  },
}
