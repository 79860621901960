import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import { useRouter, useRoute } from 'vue-router/composables'
import _ from 'lodash'
import useRequest from '@/compositions/useRequest'
import Datepicker from '@/components/Date/Default/index.vue'

export default defineComponent({
  props: {
    entity: {
      type: String,
      default: 'coef',
    },
    single: {
      type: Number,
      default: 0,
    },
    requestData: {
      type: Object,
      default: () => {},
    },
    styleType: {
      type: Number,
      default: 1,
    },
    val: {
      type: [Number, String],
      default: '-',
    },
    activatorDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Datepicker,
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()
    const model = ref(false)
    const loading = ref(false)

    const mask = (val) => {
      return '0.##'
    }

    const getUnix = (val) => {
      return moment(val, 'YYYY-MM-DD').unix()
    }

    const formTemplate = {
      1: {
        switch: {
          value: false,
          disabled: false,
          label: 'Аванс | Зарплата',
        },
        data: {
          entityValue: '',
          date_from: '',
          date_to: '',
        },
      },
      2: {
        switch: {
          value: false,
          disabled: false,
          label: 'Ежедневная',
        },
        data: {
          entityValue: '',
          date_from: '',
          date_to: '',
        },
      },
    }
    const form = ref(_.cloneDeep(formTemplate))

    const changeEntity = async () => {
      loading.value = true
      if (props.entity === 'tariff') {
        const requestData = Object.values(form.value).reduce(
          (acc, item, index) => {
            if (item.switch.value) {
              acc.push({
                date_from: moment(item.data.date_from, 'YYYY.MM.DD').format(
                  'YYYY-MM-DD'
                ),
                date_to: moment(item.data.date_to, 'YYYY.MM.DD').format(
                  'YYYY-MM-DD'
                ),
                // doljnost_id: 1, //. когда работаем с должность в зоне
                id: props.requestData.id, // айди типа
                // object_id: 11190, // только в типе должность
                sum: Number(item.data.entityValue),
                type_id: index + 1,
              })
            }
            return acc
          },
          []
        )
        const response = await store.dispatch('form/update', {
          url: `individual_price/add/${props.requestData.entity}`,
          body: { data: requestData },
        })
      } else if (props.entity === 'coef') {
        const requestData = Object.values(form.value).reduce(
          (acc, item, index) => {
            if (item.switch.value) {
              acc.push(
                store.dispatch('form/putForm', {
                  url: `coefficient/change/${props.requestData.entity}`,
                  body: {
                    data: {
                      date_from: moment(
                        item.data.date_from,
                        'YYYY.MM.DD'
                      ).format('YYYY-MM-DD'),
                      date_to: moment(item.data.date_to, 'YYYY.MM.DD').format(
                        'YYYY-MM-DD'
                      ),
                      coefficient: Number(item.data.entityValue),
                      type_id: index + 1,
                      [props.requestData.entity !== 'main'
                        ? `${props.requestData.entity}_id`
                        : undefined]: props.requestData.id,
                    },
                  },
                })
              )
            }
            return acc
          },
          []
        )
        await Promise.all(requestData).then((response) => {})

        // if (response.code === 1) {
        //   store.commit('notifies/showMessage', {
        //     color: 'success',
        //     content: 'Коэффициент изменен',
        //     timeout: 1000,
        //   })
        // } else {
        //   store.commit('notifies/showMessage', {
        //     color: 'error',
        //     content: 'Ошибка сервера',
        //     timeout: 1000,
        //   })
        // }
      }
      loading.value = false
      model.value = false
      emit('refresh')
    }

    watch(
      () => model.value,
      () => {
        if (model.value) {
          form.value = _.cloneDeep(formTemplate)
          if (props.single) {
            if (props.single === 1) form.value[2].data = undefined
            else form.value[1].data = undefined
            form.value[props.single].switch.disabled = true
            form.value[props.single].switch.value = true
          }
        }
      }
    )

    const disabled = ref(false)
    watch(
      () => form.value,
      () => {
        const singleTrue = Object.values(form.value).some((item) => {
          return item.switch.value
        })
        for (let i = 0; i < Object.values(form.value).length; i++) {
          const item = Object.values(form.value)[i]
          if (!singleTrue) {
            disabled.value = true
            return
          } else if (item.data && item.switch.value) {
            if (
              !(
                item.data.date_from &&
                item.data.date_to &&
                getUnix(item.data.date_to) >= getUnix(item.data.date_from) &&
                item.data.entityValue
              )
            ) {
              disabled.value = true
              return
            }
          }
        }
        disabled.value = false
      },
      { deep: true }
    )

    return {
      mask,
      getUnix,
      changeEntity,

      model,
      form,
      disabled,
      loading,
    }
  },
})
