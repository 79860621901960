import Vue, {
  nextTick,
  computed,
  ref,
  onMounted,
  watch,
  onUnmounted,
} from 'vue'
import { useRouter, useRoute } from 'vue-router/composables'

import useRequest from '@/compositions/useRequest'

import _ from 'lodash'
import moment from 'moment'

import store from '@/store'

export default {
  name: 'Form-Output',
  components: {},
  props: {
    stage: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const list = ref({
      // object: {},
      personal: {},
      // doljnost: {},
    })

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: async (data) => {
        const response = await store.dispatch('list/get', data)
        return response
      },
    })

    const loadingPersonal = ref(false)
    const getList = async () => {
      const arr = [
        // 'object_x5_for_parser',
        // 'personal_x5_for_parser',
        'personal_employed_registry',
        // 'doljnost_x5_for_parser',
        // 'account_x5_for_parser',
      ]
      loadingPersonal.value = true
      const response = await makeRequestList([
        {
          alias: 'personal_employed_registry',
          filter: [
            {
              alias: 'type_parser',
              value: [props.stage.outputId],
              type: 'num',
            },
          ],
        },
      ])
      loadingPersonal.value = false
      list.value = arr.reduce((acc, item) => {
        acc[item.split('_')[0]] = response.data[item].reduce((acc, item) => {
          acc[item.id] = item.name
          return acc
        }, {})
        return acc
      }, {})
    }

    const convertData = (val) => {
      return moment(val, 'YYYY-MM-DD').format('DD.MM.YYYY')
    }

    onMounted(() => {
      getList()
    })

    return {
      convertData,
      list,
      loadingPersonal,
    }
  },
}
