import Vue, { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'
import { getList } from '@/api/selects'
import moment from 'moment/moment'
import store from '@/store'
import _ from 'lodash'
import { useRouter, useRoute } from 'vue-router/composables'
import useRequest from '@/compositions/useRequest'

import plug from '@/components/Contract/plug'
import exPanel from '@/components/Contract/exPanel'
import Doljnost from '@/components/Contract/doljnost'

const ObjectTarif = defineComponent({
  id: uuidv4(),
  components: {
    exPanel,
    Doljnost,
    plug,
  },
  props: {
    mainData: {
      type: Object,
      default: () => {},
    },
  },
  path: 'edit',
  name: 'Тарифы',
  isShow: {
    value: true,
    condition: [
      {
        funcCondition: (ctx) => {
          if (
            [1, 2, 3, 4, 7, 9, 16, 19].includes(
              ctx.store.state.user.permission_id
            )
          ) {
            if (
              ctx.mainData.direction_json?.includes(2) &&
              [1, 2, 12].includes(ctx.mainData.type)
            )
              return true
          }
          return false
        },
      },
    ],
  },
  setup(props, ctx) {
    const { emit } = ctx
    const route = useRoute()
    const router = useRouter()

    const services = ref([])
    const loading = ref(false)
    const objectId = computed(() => Number(route.params.id))

    const context = {
      root: {
        store,
        router,
        ctx,
        route,
      },
    }

    const { makeRequest: makeRequestList } = useRequest({
      context,
      request: async (data) => {
        const response = await store.dispatch('list/get', data)
        return response
      },
    })

    const getService = async () => {
      loading.value = true
      const response = await makeRequestList([
        {
          alias: 'object_active_doljnost',
          filter: [
            {
              alias: 'object_id',
              value: [objectId.value],
            },
          ],
        },
      ])
      response.data.object_active_doljnost.forEach((item) => {
        Vue.set(item, 'loaded', null)
        Vue.set(item, 'items', null)
      })
      services.value = response.data.object_active_doljnost
      loading.value = false
    }

    onMounted(() => {
      getService()
    })

    return {
      loading,
      services,
      objectId,
    }
  },
})
export default ObjectTarif
