var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticStyle:{"padding-top":"20px"}},[_c('PersTitle',{attrs:{"data":{
        surname: _vm.data.entity.surname,
        name_n: _vm.data.entity.name_n,
        patronymic: _vm.data.entity.patronymic,
        dataRojd: _vm.data.entity.data_rojd.split('-').reverse().join('.'),
      }}}),(_vm.textInfo)?_c('TextInfo',{staticClass:"mb-2",attrs:{"infoObj":_vm.textInfo}}):_vm._e(),_c('div',{staticClass:"mb-10"},[_c('span',{staticClass:"font-weight-bold mb-4"},[_vm._v("Приложите документы: ")]),_c('div',{staticClass:"mt-3"},[_c('v-expansion-panels',{attrs:{"multiple":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[(!_vm.listDocuments[0].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconGalka ")]):_vm._e(),(_vm.listDocuments[0].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconSetting ")]):_vm._e(),_vm._v(" Регистрация ")],1)]),_c('v-expansion-panel-content',[_c('Dropzone',{attrs:{"options":{
                  withoutSave: false,
                  folder: 'tmp',
                  removeble: false,
                },"paramsForEmit":{ item: 4 }},on:{"addFiles":_vm.addFiles}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[(!_vm.listDocuments[1].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconGalka ")]):_vm._e(),(_vm.listDocuments[1].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconSetting ")]):_vm._e(),_vm._v(" Регистрация стр.2 ")],1)]),_c('v-expansion-panel-content',[_c('Dropzone',{attrs:{"options":{
                  withoutSave: false,
                  folder: 'tmp',
                  removeble: false,
                },"paramsForEmit":{ item: 14 }},on:{"addFiles":_vm.addFiles}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[(!_vm.listDocuments[2].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconGalka ")]):_vm._e(),(_vm.listDocuments[2].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconSetting ")]):_vm._e(),_vm._v(" ИНН ")],1)]),_c('v-expansion-panel-content',[_c('Dropzone',{attrs:{"options":{
                  withoutSave: false,
                  folder: 'tmp',
                  removeble: false,
                },"paramsForEmit":{ item: 17 }},on:{"addFiles":_vm.addFiles}})],1)],1),_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('span',[(!_vm.listDocuments[3].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconGalka ")]):_vm._e(),(_vm.listDocuments[3].inProcess)?_c('v-icon',{attrs:{"left":""}},[_vm._v(" $IconSetting ")]):_vm._e(),_vm._v(" Ученический договор ")],1)]),_c('v-expansion-panel-content',[_c('Dropzone',{attrs:{"options":{
                  withoutSave: false,
                  folder: 'tmp',
                  removeble: false,
                },"paramsForEmit":{ item: 25 }},on:{"addFiles":_vm.addFiles}})],1)],1)],1)],1)]),_c('v-row',{staticClass:"py-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('closePopup')}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" Закрыть ")],1),_c('v-btn',{staticClass:"action_save",attrs:{"small":"","color":"info","disabled":_vm.listDisbledDocuments !== 0,"loading":_vm.loading},on:{"click":_vm.sendTaskFinish}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-save")]),_vm._v(" Завершить ")],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }