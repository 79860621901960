var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-menu',{attrs:{"close-on-click":!_vm.loading,"top":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"px-0",attrs:{"elevation":"0","height":"40","disabled":_vm.disabled,"min-width":"40","text":"","color":"primary"}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[_c('v-icon',{attrs:{"color":"primary","size":"24"}},[_vm._v(_vm._s(_vm.action.icon))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.action.tooltip))])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-6",attrs:{"width":"370"}},[_c('div',{staticClass:"text--text font-size-24 font-weight-700"},[_vm._v(" "+_vm._s(_vm.action.tooltip)+" тариф ")]),_c('v-divider',{staticClass:"my-3"}),_c('div',[(_vm.action.type === 'off')?_c('v-row',[_vm._v("Вы уверены, что хотите отключить данный тариф?")]):_vm._e(),(_vm.action.type === 'off' || _vm.action.type === 'prolongation')?_c('v-row',{staticClass:"d-block py-2 px-3",style:({
          backgroundColor:
            'color-mix(in srgb, var(--v-secondary-base) 10%, white)',
        })},[_c('v-row',{staticClass:"justify-space-between"},[_c('div',{staticClass:"secondary--text"},[_vm._v(_vm._s(_vm.data.type_name))]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"secondary--text font-weight-700"},[_vm._v(" "+_vm._s(_vm.data.sum)+" ₽ ")]),_c('div',{staticClass:"ml-2 mr-3",staticStyle:{"color":"color-mix(in srgb, var(--v-secondary-base) 70%, white)"}},[_vm._v(" (К: "+_vm._s(_vm.data.coefficient)+") ")])])]),_c('v-row',[_c('v-icon',{staticClass:"mr-2 cursor-default",attrs:{"size":"24","color":"secondary"}},[_vm._v("mdi-calendar-clock-outline")]),_c('div',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.convertDate(_vm.data.date_from))+" — "+_vm._s(_vm.convertDate(_vm.data.date_to))+" ")])],1)],1):_vm._e(),(_vm.action.type === 'edit')?_c('v-row',{staticClass:"d-block"},[_c('div',{staticClass:"secondary--text mb-2"},[_vm._v("Сумма, ₽")]),_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","color":"secondary","outlined":"","readonly":_vm.loading},model:{value:(_vm.formData.sum),callback:function ($$v) {_vm.$set(_vm.formData, "sum", $$v)},expression:"formData.sum"}})],1)],1)],1):_vm._e(),(_vm.action.type === 'edit' || _vm.action.type === 'prolongation')?_c('v-row',{staticClass:"d-block"},[_c('div',{staticClass:"secondary--text mb-2"},[_vm._v("Срок действия")]),_c('v-row',[_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"5"}},[_c('Datepicker',{attrs:{"options":{
                dense: true,
                'hide-options': true,
                outlined: true,
                clearable: false,
              },"readonly":_vm.action.type === 'prolongation' || _vm.loading},model:{value:(_vm.formData.date_from),callback:function ($$v) {_vm.$set(_vm.formData, "date_from", $$v)},expression:"formData.date_from"}})],1),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12","sm":"2"}},[_c('v-icon',{staticClass:"cursor-default",attrs:{"color":"textGray","size":"24"}},[_vm._v("mdi-arrow-right")])],1),_c('v-col',{staticClass:"px-0",attrs:{"cols":"12","sm":"5"}},[_c('Datepicker',{attrs:{"options":{
                dense: true,
                'hide-options': true,
                outlined: true,
                clearable: false,
              },"edge":"right","readonly":_vm.loading},model:{value:(_vm.formData.date_to),callback:function ($$v) {_vm.$set(_vm.formData, "date_to", $$v)},expression:"formData.date_to"}})],1)],1)],1):_vm._e()],1),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-none",attrs:{"loading":_vm.loading,"color":"primary","outlined":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v("Отменить")]),(_vm.action.type !== 'off')?_c('v-btn',{staticClass:"ml-2 text-none",attrs:{"elevation":"0","disabled":_vm.acceptDisable,"color":"primary","loading":_vm.loading},on:{"click":_vm.accept}},[_vm._v("Применить")]):_vm._e(),(_vm.action.type === 'off')?_c('v-btn',{staticClass:"ml-2 text-none",attrs:{"elevation":"0","color":"error","loading":_vm.loading},on:{"click":_vm.accept}},[_vm._v("Отключить")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }