var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    'v-upper',
    (_vm.$route.query.filter === 'folder' ||
      _vm.$route.query.filter === 'box' ||
      _vm.$route.query.filter === 'trans') &&
      !_vm.$route.query.mail &&
      'v-upper__wide',
    'justify-space-between',
  ]},[_c('div',{staticClass:"v-upper-user d-flex"},[_c('div',{staticClass:"v-upper-user-content"},[_c('div',{staticClass:"v-upper-user-content_name"},[_vm._v(" "+_vm._s(_vm.$props?.data?.message_from)+" ")]),_c('div',{staticClass:"v-upper-user-content_email"})])]),(!_vm.$props.expanded)?_c('div',{staticClass:"v-upper-container"},[(
        !_vm.$props?.data?.is_read &&
        _vm.$route.query.filter !== 'sent' &&
        _vm.$route.query.filter !== 'trash'
      )?_c('div',{staticClass:"v-upper-container-viewed"}):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }