var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"zayavka-item mb-1"},[(_vm.fields.length)?_c('v-row',_vm._l((_vm.fields),function(field){return _c('v-col',{key:field.id,ref:"fieldsRef",refInFor:true,staticClass:"field-col",class:[
        field.type,
        typeof field.isShow === 'object' && !field.isShow.value
          ? 'isHide'
          : '',
        field.name === 'accept_sum' ? 'ml-auto' : '',
      ],attrs:{"cols":field.position.cols,"sm":field.position.sm,"name":field.name}},[(
          _vm.loading &&
          field.isShow &&
          ((typeof field.isShow === 'boolean' && field.isShow) ||
            (typeof field.isShow === 'object' && field.isShow.value))
        )?_c('div',{staticClass:"field-loading gradient"}):(field.type === 'select')?_c('Autocomplete',{ref:"autocompleteRef",refInFor:true,class:[...field.class],attrs:{"field":field,"formData":_vm.formData,"readonly":true},on:{"change":_vm.changeAutocomplete},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'string')?_c('v-text-field',{attrs:{"label":field.label,"placeholder":field?.placeholder,"clearable":"","readonly":field.name === 'accept_sum' ? false : true,"name":field.name,"error-messages":field.name === 'accept_sum' ? _vm.errorTextShow && _vm.errorText : []},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):(field.type === 'checkbox')?_c('v-checkbox',{class:'checkbox_' + field.name,attrs:{"label":field.label,"readonly":true,"name":field.name},model:{value:(_vm.formData[field.name]),callback:function ($$v) {_vm.$set(_vm.formData, field.name, $$v)},expression:"formData[field.name]"}}):_vm._e()],1)}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }