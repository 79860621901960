var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('span',[_vm._t("activator",null,{"on":() => (_vm.isShow = true)}),_c('v-dialog',{attrs:{"persistent":"","width":"780"},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('v-card',{staticClass:"pa-6"},[_c('v-row',{staticClass:"align-center justify-space-between"},[_c('div',{staticClass:"d-flex text-center"},[_c('v-icon',{staticClass:"mr-3",attrs:{"color":"textGray","size":"24"}},[_vm._v("mdi-cog-clockwise")]),_c('span',{staticClass:"text--text line-normal font-weight-700 font-size-24"},[_vm._v("Тарифы")])],1),_c('div',{staticClass:"d-flex align-center"},[_c('v-switch',{staticClass:"mt-0 pt-0",attrs:{"dense":"","hide-details":"","inset":""},on:{"change":_vm.initItems},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}}),_c('span',{staticClass:"text--text"},[_vm._v("Показать неактивные")])],1)]),_c('v-divider',{staticClass:"my-3"}),_c('v-card',{staticClass:"overflow-auto",attrs:{"max-height":"400","elevation":"0"}},[(_vm.loading)?_c('div',{staticClass:"text-center py-4"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1):(!_vm.items.length)?_c('plug',{attrs:{"text":"Тарифы не найдены"}}):[_vm._l((_vm.items),function(item,index){return _c('v-row',{directives:[{name:"intersect",rawName:"v-intersect.once",value:(item.intersecting && _vm.getItems),expression:"item.intersecting && getItems",modifiers:{"once":true}}],key:index,staticClass:"mt-0 py-2 pl-2",style:({
              backgroundColor:
                !item.is_active &&
                'color-mix(in srgb, var(--v-secondary-base) 10%, white)',
            })},[_c('v-col',{staticClass:"px-0 d-flex align-center",attrs:{"cols":"12","sm":"3"}},[_c('div',{staticClass:"mr-3",staticStyle:{"width":"6px","height":"6px","border-radius":"50%"},style:({
                  backgroundColor: item.is_active
                    ? 'var(--v-success-base)'
                    : 'var(--v-error-base)',
                })}),_c('div',{staticClass:"secondary--text"},[_vm._v(_vm._s(item.type_name))])]),_c('v-col',{staticClass:"px-0 d-flex align-center justify-end",staticStyle:{"white-space":"nowrap"},attrs:{"cols":"12","sm":"6"}},[_c('div',{staticClass:"secondary--text font-weight-700"},[_vm._v(" "+_vm._s(item.sum)+" ₽ ")]),_c('div',{staticClass:"ml-2 mr-3",staticStyle:{"color":"color-mix("}},[_vm._v(" (К: "+_vm._s(item.coefficient)+") ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-2 cursor-default",attrs:{"size":"24","color":"secondary"}},[_vm._v("mdi-calendar-clock-outline")]),_c('div',{staticClass:"secondary--text"},[_vm._v(" "+_vm._s(_vm.convertDate(item.date_from))+" — "+_vm._s(_vm.convertDate(item.date_to))+" ")])],1)]),_c('v-col',{staticClass:"px-0 d-flex justify-end",attrs:{"cols":"12","sm":"3"}},[_vm._l((_vm.actionsArray),function(action){return _c('action',{key:action.type,attrs:{"action":action,"data":item,"disabled":!item.is_active},on:{"refresh":_vm.initItems}})}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"color":"secondary","size":"24"}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.create_account_name)+" "+_vm._s(_vm.convertDateExternal(item.date_create)))])])],2)],1)}),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"color":"primary","size":32,"indeterminate":""}})],1):_vm._e()]],2),_c('v-divider',{staticClass:"my-3"}),_c('v-row',{staticClass:"justify-end"},[_c('v-btn',{staticClass:"text-none",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.isShow = false}}},[_vm._v("Закрыть")])],1)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }