import {
  dateField,
  stringField,
  selectField,
  autocompleteField,
  textareaField,
  datetimeField,
  checkboxField,
  dropZoneField,
  carouselField,
  docListField,
  textBlock,
} from '@/utils/fields.js'
import { stringAction } from '@/utils/actions'
import {
  required,
  number,
  hasTime,
  dayOfMonth,
  minFileLength,
} from '@/utils/validation.js'
import { v4 as uuidv4 } from 'uuid'

export default {
  id: uuidv4(),
  name: 'Редактировать недвижимость',
  type: 'FormDefault',
  path: 'add',
  alias: 'realty',
  active: false,
  detail: true,
  lists: [
    {
      alias: 'realty_payment_type',
      filter: [],
    },
  ],
  fields: [
    stringField({
      label: 'Название',
      name: 'name',
      placeholder: '',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'Адрес',
      name: 'address',
      placeholder: '',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    autocompleteField({
      label: 'Ответственный',
      name: 'account_id',
      subtype: 'single',
      url: 'get/pagination_list/realty_account_id',
      placeholder: '',
      class: [''],
      page: 1,
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      position: {
        cols: 12,
        sm: 6,
      },
      dependence: [
        {
          type: 'api',
          module: 'selects/getListUpdate',
          field: 'object_id',
          url: 'get/pagination_list/realty_object_id',
        },
      ],
      updateList: [
        {
          alias: 'req_zr_id',
          filter: [
            {
              field: 'account_id',
              source: 'formData',
              value: '',
              type: 'num',
            },
            {
              field: 'payment_type',
              alias: 'type_pay',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              field: 'object_id',
              alias: 'object_zr',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              alias: 'direction_id',
              sendEmpty: true,
              value: [7],
              type: 'num',
            },
            {
              alias: 'type_objects',
              sendEmpty: true,
              value: [1],
              type: 'num',
            },
            {
              alias: 'vector_id',
              sendEmpty: true,
              value: [2],
              type: 'num',
            },
          ],
        },
      ],
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'Телефон',
      name: 'responsible_phone',
      placeholder: '',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'Кадастровый номер',
      name: 'cadastral_number',
      placeholder: 'XX:XX:XXXXXXX:X',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
    }),
    autocompleteField({
      label: 'Объект',
      name: 'object_id',
      url: 'get/pagination_list/realty_object_id',
      subtype: 'single',
      placeholder: '',
      class: [''],
      page: 1,
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      requiredFields: ['account_id'],
      filter: [
        {
          field: 'account_id',
          alias: 'ot.account_id',
          value: '',
          source: 'formData',
          type: 'num',
        },
      ],
      position: {
        cols: 12,
        sm: 6,
      },
      updateList: [
        {
          alias: 'req_zr_id',
          filter: [
            {
              field: 'account_id',
              source: 'formData',
              value: '',
              type: 'num',
            },
            {
              field: 'payment_type',
              alias: 'type_pay',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              field: 'object_id',
              alias: 'object_zr',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              alias: 'direction_id',
              sendEmpty: true,
              value: [7],
              type: 'num',
            },
            {
              alias: 'type_objects',
              sendEmpty: true,
              value: [1],
              type: 'num',
            },
            {
              alias: 'vector_id',
              sendEmpty: true,
              value: [2],
              type: 'num',
            },
          ],
        },
      ],
      validations: { required },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'Площадь (м²)',
      name: 'square',
      placeholder: '',
      requestType: 'number',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required, number },
      bootstrapClass: [''],
    }),
    stringField({
      label: 'День платежа',
      name: 'date_pay',
      placeholder: '',
      requestType: 'number',
      value: '',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required, number, dayOfMonth },
      mask: 'X#',
      bootstrapClass: [''],
    }),
    stringField({
      label: 'Сумма платежа',
      name: 'sum',
      placeholder: '',
      value: '',
      requestType: 'number',
      class: [''],
      position: {
        cols: 12,
        sm: 6,
      },
      validations: { required, number },
      bootstrapClass: [''],
    }),
    dateField({
      label: 'Дата следующего платежа',
      name: 'next_rent_date',
      type: 'date',
      value: '',
      notSend: true,
      menu: false,
      placeholder: '',
      class: [''],
      readonly: true,
      position: {
        cols: 12,
        sm: 12,
      },
      // validations: { required },
      bootstrapClass: [''],
      isShow: {
        value: false,
        conditions: [
          {
            field: 'price_of_registration',
            target: 'value',
            value: 'notEmpty',
          },
        ],
      },
      // disable: false,
      //mode: 'edit',
    }),
    selectField({
      label: 'Тип оплаты',
      name: 'payment_type',
      alias: 'realty_payment_type',
      subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      items: [],
      position: {
        cols: 12,
        sm: 12,
      },
      updateList: [
        {
          alias: 'req_zr_id',
          filter: [
            {
              field: 'account_id',
              source: 'formData',
              value: '',
              type: 'num',
            },
            {
              field: 'payment_type',
              alias: 'type_pay',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              field: 'object_id',
              alias: 'object_zr',
              value: '',
              source: 'formData',
              type: 'num',
            },
            {
              alias: 'direction_id',
              sendEmpty: true,
              value: [7],
              type: 'num',
            },
            {
              alias: 'type_objects',
              sendEmpty: true,
              value: [1],
              type: 'num',
            },
            {
              alias: 'vector_id',
              sendEmpty: true,
              value: [2],
              type: 'num',
            },
          ],
        },
      ],
      validations: { required },
      bootstrapClass: [''],
    }),
    selectField({
      label: 'Реквизиты',
      name: 'rek_id',
      alias: 'req_zr_id',
      subtype: 'single',
      placeholder: '',
      class: [''],
      selectOption: {
        text: 'name',
        value: 'id',
      },
      position: {
        cols: 12,
        sm: 12,
      },
      validations: { required },
      bootstrapClass: [''],
      isShow: {
        value: true,
        conditions: [{ field: 'payment_type', value: [2, 3] }],
      },
    }),
    dropZoneField({
      label: 'Счет',
      name: 'schet_loader',
      requestKey: 'schet',
      notPut: true,
      placeholder: '',
      class: [''],
      grouping: 'multiple',
      isShow: {
        value: false,
        conditions: [{ field: 'payment_type', value: [4] }],
      },
      position: {
        cols: 12,
        sm: 12,
      },
      bootstrapClass: [''],
      validations: {
        // required,
        minFileLength,
      },
      options: {
        removeble: true,
        withoutSave: false,
        folder: 'schet',
        name: '`realty_doc`',
        paramsForEmit: this,
        countFiles: 10,
        placeholder: 'Переместите или выберите счет',
        stash: 'schet',
      },
      value: [],
    }),
    docListField({
      name: 'schet',
      class: [''],
      position: {
        cols: 12,
        sm: 12,
      },
    }),
  ],
  actions: [
    stringAction({
      text: 'Закрыть',
      type: 'submit',
      color: 'textDefault',
      name: 'closePopup',
      action: 'closePopup',
      skipValidation: true,
    }),
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      color: 'primary',
      module: 'form/create',
      url: 'create/realty',
      name: 'saveFormStore',
      action: 'saveFormStore',
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            field: 'mode',
            target: 'environment',
            value: ['edit'],
            type: true,
          },
        ],
      },
    }),
    stringAction({
      text: 'Сохранить',
      type: 'submit',
      module: 'form/putForm',
      url: 'update/realty',
      name: 'updateFormStore',
      action: 'updateFormStore',
      color: 'primary',
      isHide: {
        value: false,
        type: 'every',
        condition: [
          {
            field: 'mode',
            target: 'environment',
            value: ['add'],
            type: true,
          },
        ],
      },
    }),
  ],
}
